/**
 * Variables
*/
/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
/* Components */
.btn-bordered, .campaign-box-ui .add-to-cart-button {
  border: 1px solid #ccc;
  border-radius: 21px;
  height: 28px;
  padding: 0 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000;
  letter-spacing: -0.4px;
  font-size: 12px;
  line-height: 19px;
  background: #ffffff;
  cursor: pointer;
  font-weight: 400;
  font-family: 'Galano Grotesque Medium', sans-serif;
  -moz-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s; }
  .btn-bordered.added, .campaign-box-ui .added.add-to-cart-button, .btn-bordered:active, .campaign-box-ui .add-to-cart-button:active {
    color: #ffffff;
    border-color: #4200FF;
    background-color: #4200FF; }
    .btn-bordered.added:hover, .campaign-box-ui .added.add-to-cart-button:hover, .btn-bordered:active:hover, .campaign-box-ui .add-to-cart-button:active:hover {
      color: #ffffff;
      border-color: #4200FF;
      background-color: #4200FF; }
  .btn-bordered.mobile-button, .campaign-box-ui .mobile-button.add-to-cart-button {
    width: 202px;
    height: 63px;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

.global-btn {
  height: 61px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.16px;
  border: 0;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
  font-weight: 400;
  font-family: 'Galano Grotesque SemiBold', sans-serif; }
  .global-btn.careempay {
    background: transparent;
    border: none;
    height: auto; }
    .global-btn.careempay:hover {
      background: transparent;
      border: none; }
  .global-btn.is-disabled {
    background: #e3e3e3;
    pointer-events: none;
    color: #fff; }
  .global-btn.bordered {
    border: 1px solid #ccc;
    background: transparent;
    color: #000; }
  .global-btn.extraPadding {
    padding: 0 20px; }
  .global-btn.paypal {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFC239), color-stop(122.3%, #FFB000), color-stop(122.31%, #FFB000));
    background: linear-gradient(180deg, #FFC239 0%, #FFB000 122.3%, #FFB000 122.31%); }
    .global-btn.paypal img {
      width: 23%; }
    .global-btn.paypal::after {
      display: none; }
    .global-btn.paypal:focus, .global-btn.paypal:hover, .global-btn.paypal:active {
      background: -webkit-gradient(linear, left top, left bottom, from(#FFC239), color-stop(122.3%, #FFB000), color-stop(122.31%, #FFB000));
      background: linear-gradient(180deg, #FFC239 0%, #FFB000 122.3%, #FFB000 122.31%); }
  .global-btn.hide {
    display: none !important; }

.hoveredBtn, .btn-bordered:hover, .campaign-box-ui .add-to-cart-button:hover, .global-btn, .featured-campaign .learn-more-link {
  color: #ffffff;
  border-color: #4200FF;
  background-color: #4200FF;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  cursor: pointer; }

.revHoveredBtn, .global-btn:hover {
  color: #ffffff;
  border-color: #4200FF;
  background-color: #4200FF;
  background: -webkit-gradient(linear, left top, left bottom, from(#393ec2), to(#3503c2));
  background: linear-gradient(180deg, #393ec2, #3503c2);
  cursor: pointer; }

.activeBtn, .global-btn:active, .global-btn:focus {
  color: #ffffff;
  border-color: #4200FF;
  background-color: #4200FF;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  background-image: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
  cursor: pointer; }

.is-disabled {
  pointer-events: none !important; }

#addToCartLoader svg {
  width: 110px !important; }

#main-page-slider {
  margin: 0 0 26px;
  border-bottom-left-radius: 36px;
  border-bottom-right-radius: 36px;
  overflow: hidden; }
  #main-page-slider .swiper-container {
    position: relative; }
  #main-page-slider .swiper-slide {
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat; }
  #main-page-slider .content-box::before, #main-page-slider .content-box::after {
    content: '';
    clear: both; }
  #main-page-slider .content-box::after {
    display: table; }
  #main-page-slider .content-box .content-left {
    padding: 13px 33px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-30.22%, #f55151), color-stop(160.74%, #9f041b));
    background: linear-gradient(180deg, #f55151 -30.22%, #9f041b 160.74%);
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: 308px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    #main-page-slider .content-box .content-left.content-top {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
    #main-page-slider .content-box .content-left.no-bg {
      background: none; }
    #main-page-slider .content-box .content-left.align-left h3,
    #main-page-slider .content-box .content-left.align-left h4,
    #main-page-slider .content-box .content-left.align-left h5 {
      text-align: left; }
    #main-page-slider .content-box .content-left.align-left a {
      -ms-flex-item-align: start;
          align-self: flex-start; }
    #main-page-slider .content-box .content-left.align-right h3,
    #main-page-slider .content-box .content-left.align-right h4,
    #main-page-slider .content-box .content-left.align-right h5 {
      text-align: right; }
    #main-page-slider .content-box .content-left.align-right a {
      -ms-flex-item-align: end;
          align-self: flex-end; }
    @media (max-width: 1360px) {
      #main-page-slider .content-box .content-left {
        min-height: 309px; } }
  #main-page-slider .content-box .content-right {
    padding: 23px 33px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-30.22%, #f55151), color-stop(160.74%, #9f041b));
    background: linear-gradient(180deg, #f55151 -30.22%, #9f041b 160.74%);
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 308px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    float: right;
    text-align: right; }
    #main-page-slider .content-box .content-right.no-bg {
      background: none; }
    #main-page-slider .content-box .content-right.content-top {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
    #main-page-slider .content-box .content-right.align-left h3,
    #main-page-slider .content-box .content-right.align-left h4,
    #main-page-slider .content-box .content-right.align-left h5 {
      text-align: left; }
    #main-page-slider .content-box .content-right.align-left a {
      -ms-flex-item-align: start;
          align-self: flex-start; }
    #main-page-slider .content-box .content-right.align-right h3,
    #main-page-slider .content-box .content-right.align-right h4,
    #main-page-slider .content-box .content-right.align-right h5 {
      text-align: right; }
    #main-page-slider .content-box .content-right.align-right a {
      -ms-flex-item-align: end;
          align-self: flex-end; }
    @media (max-width: 1360px) {
      #main-page-slider .content-box .content-right {
        min-height: 309px; } }
  #main-page-slider .content-box.swiper-slide-active h3 {
    -webkit-animation: fadeInUp;
            animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s; }
  #main-page-slider .content-box.swiper-slide-active h4 {
    -webkit-animation: fadeInUp;
            animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
  #main-page-slider .content-box.swiper-slide-active h5 {
    -webkit-animation: fadeInUp;
            animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
  #main-page-slider .content-box.swiper-slide-active a {
    -webkit-animation: fadeInUp;
            animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
  #main-page-slider h3 {
    letter-spacing: -4px;
    color: #ffffff;
    font-style: italic;
    font-size: 90px;
    line-height: 73px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  #main-page-slider h4 {
    color: #ffffff;
    letter-spacing: -1.44845px;
    font-size: 31px;
    line-height: 33px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  #main-page-slider h5 {
    font-size: 28px;
    line-height: 28px;
    color: #fff;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
  #main-page-slider .new-banner-copy {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    #main-page-slider .new-banner-copy h1 {
      font-size: 45px;
      line-height: 48px;
      letter-spacing: -1.09px;
      margin: 0 0 20px;
      color: #fff;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif; }
      #main-page-slider .new-banner-copy h1 b {
        font-family: "ABC Ginto Nord Bold"; }
    #main-page-slider .new-banner-copy.left-align {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      left: 62px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      #main-page-slider .new-banner-copy.left-align h3 {
        letter-spacing: -2px;
        margin: 0 0 0 -12px;
        padding-right: 10px;
        font-family: "ABC Ginto Nord Bold Italic";
        background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        line-height: 95px;
        font-size: 90px;
        margin-bottom: -15px;
        display: inline-block; }
      #main-page-slider .new-banner-copy.left-align h4 {
        font-size: 35px;
        line-height: 36px;
        color: #fff;
        letter-spacing: -0.8px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif;
        margin: 0; }
      #main-page-slider .new-banner-copy.left-align h5 {
        font-size: 22px;
        line-height: 22px;
        color: #fff;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        margin: 0 0 23px; }
        #main-page-slider .new-banner-copy.left-align h5 b, #main-page-slider .new-banner-copy.left-align h5 span {
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif;
          color: #4200FF; }
  #main-page-slider .readMore {
    background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
    background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
    height: 59px;
    padding: 0 54px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
    font-size: 18px;
    letter-spacing: -0.16px;
    font-weight: 400;
    font-family: 'Galano Grotesque SemiBold', sans-serif; }
  #main-page-slider .swiper-button-prev,
  #main-page-slider .swiper-button-next {
    width: 58px;
    height: 48px;
    border: 1px solid rgba(255, 255, 255, 0.324765);
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    position: absolute;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    #main-page-slider .swiper-button-prev:hover,
    #main-page-slider .swiper-button-next:hover {
      border-color: white;
      outline: 0; }
      #main-page-slider .swiper-button-prev:hover::after,
      #main-page-slider .swiper-button-next:hover::after {
        color: white; }
    #main-page-slider .swiper-button-prev::after,
    #main-page-slider .swiper-button-next::after {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.324765);
      outline: 0; }
  #main-page-slider .swiper-button-prev {
    right: 101px;
    bottom: 33px;
    left: auto;
    top: auto; }
  #main-page-slider .swiper-button-next {
    right: 33px;
    bottom: 33px;
    left: auto;
    top: auto; }
  @media (max-width: 1360px) {
    #main-page-slider h3 {
      font-size: 80px; }
    #main-page-slider h4 {
      font-size: 26px;
      line-height: 28px; }
    #main-page-slider h5 {
      font-size: 23px;
      line-height: 23px; } }
  @media (max-width: 1023px) {
    #main-page-slider h3 {
      font-size: 60px;
      line-height: 50px; }
    #main-page-slider h4 {
      font-size: 20px;
      line-height: 22px; }
    #main-page-slider h5 {
      font-size: 17px;
      line-height: 17px; } }

.section-title {
  font-family: "ABC Ginto Nord Bold";
  letter-spacing: -0.612857px;
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 16px; }

.closing-soon-campaigns,
.product-area {
  padding-bottom: 33px;
  overflow: hidden;
  margin: 0 36px; }
  .closing-soon-campaigns .swiper-container,
  .product-area .swiper-container {
    overflow: visible; }
  .closing-soon-campaigns .swiper-slide,
  .product-area .swiper-slide {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 233px; }

.campaign-slider-box {
  padding-bottom: 33px;
  overflow: hidden; }
  .campaign-slider-box .swiper-container {
    overflow: visible; }
  .campaign-slider-box .swiper-slide {
    width: 203px; }

.product-area {
  padding-top: 33px;
  position: relative; }
  .product-area .swiper-slide {
    cursor: pointer; }
  .product-area .swiper-button-prev,
  .product-area .swiper-button-next {
    width: 58px;
    height: 48px;
    border: 1px solid #d8d8d8;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    position: absolute;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .product-area .swiper-button-prev:hover,
    .product-area .swiper-button-next:hover {
      border-color: #efefef;
      background: #efefef;
      outline: 0; }
      .product-area .swiper-button-prev:hover::after,
      .product-area .swiper-button-next:hover::after {
        color: #000;
        outline: 0; }
    .product-area .swiper-button-prev:active,
    .product-area .swiper-button-next:active {
      outline: 0; }
    .product-area .swiper-button-prev:focus,
    .product-area .swiper-button-next:focus {
      outline: 0; }
    .product-area .swiper-button-prev::after,
    .product-area .swiper-button-next::after {
      font-size: 12px;
      color: #000;
      outline: 0; }
  .product-area .swiper-button-prev {
    right: 68px;
    top: 35px;
    left: auto; }
  .product-area .swiper-button-next {
    right: 0;
    top: 35px;
    left: auto; }

.campaign-box-ui {
  background: #ffffff;
  -webkit-box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.04);
          box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.04);
  height: auto;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s; }
  .campaign-box-ui .sold-out-bar {
    width: 100%;
    margin-bottom: 5px; }
    .campaign-box-ui .sold-out-bar .sold-out-indicator {
      height: 9px;
      margin-bottom: 2px; }
      .campaign-box-ui .sold-out-bar .sold-out-indicator span {
        height: 9px; }
    .campaign-box-ui .sold-out-bar p {
      text-align: left; }
  .campaign-box-ui .closing-soon-copy-section {
    padding: 6px 14px 17px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%; }
    .campaign-box-ui .closing-soon-copy-section .closing-soon__item-title {
      height: 30px;
      overflow: hidden; }
  .campaign-box-ui .image-container {
    margin-bottom: 4px;
    min-height: 104px;
    overflow: hidden; }
    .campaign-box-ui .image-container img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; }
  .campaign-box-ui h3 {
    letter-spacing: -0.814286px;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin: 0;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-ui h3 b {
      font-family: "ABC Ginto Nord Bold Italic";
      background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      line-height: 95px;
      font-size: 90px;
      margin-bottom: -15px;
      display: inline-block;
      font-size: 17px;
      line-height: 19px;
      text-transform: capitalize;
      padding-right: 10px; }
  .campaign-box-ui h2 {
    letter-spacing: -0.495px;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    margin: 0 0 7px;
    width: 100%;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .campaign-box-ui .add-to-cart-button {
    overflow: hidden;
    padding: 0;
    width: 100%; }
    .campaign-box-ui .add-to-cart-button.remove-to-cart {
      background: #ff6262;
      color: #ffffff;
      border-color: #ff6262; }
  .campaign-box-ui .clossing-soon__cart-actions {
    position: relative;
    height: 29px;
    padding: 4px 0;
    margin: 0 0 0 0;
    position: relative; }
    .campaign-box-ui .clossing-soon__cart-actions h4 {
      font-size: 9px;
      line-height: 8px;
      letter-spacing: -0.52px;
      color: #4f4f4f;
      margin: 0;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      text-align: left; }
    .campaign-box-ui .clossing-soon__cart-actions h5 {
      font-size: 13px;
      line-height: 14px;
      letter-spacing: -0.39px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif;
      margin: 0;
      text-align: left;
      color: #4200FF; }
    .campaign-box-ui .clossing-soon__cart-actions .plus-icon {
      background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
      background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .campaign-box-ui .clossing-soon__cart-actions .plus-icon svg {
        margin-top: 2px; }
  .campaign-box-ui .minus-from-cart-btn:hover {
    background: #e7e7e7; }
  .campaign-box-ui.product-ui {
    height: auto;
    padding: 0 0 15px;
    overflow: hidden; }
    .campaign-box-ui.product-ui h3 {
      font-size: 18px;
      line-height: 17px;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui.product-ui h2 {
      font-size: 15px;
      line-height: 17px;
      letter-spacing: -0.7px;
      color: #48b650;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui.product-ui .image-container {
      text-align: center;
      margin-bottom: 19px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 320px;
      overflow: hidden;
      position: relative; }
      .campaign-box-ui.product-ui .image-container img {
        margin: 0 auto;
        max-height: auto;
        width: 100%;
        height: auto; }
      .campaign-box-ui.product-ui .image-container .modesh-logo {
        width: 40px;
        position: absolute;
        left: 15px;
        top: 15px; }
      .campaign-box-ui.product-ui .image-container.alignCenter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
  .campaign-box-ui.closing-soon__item .campaign-action {
    position: absolute;
    right: -7px;
    bottom: -2px;
    padding: 2px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 40px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #fff; }
    .campaign-box-ui.closing-soon__item .campaign-action .cart-quantity {
      pointer-events: none; }
    .campaign-box-ui.closing-soon__item .campaign-action .add-to-cart-button {
      background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
      background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .campaign-box-ui.closing-soon__item .campaign-action .add-to-cart-button svg {
        margin-top: 2px; }
    .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: transparent;
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      overflow: hidden;
      position: relative;
      margin-right: 0;
      border: 0; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn span {
        position: relative;
        z-index: 4;
        height: 20px;
        margin-left: 0; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background: -webkit-gradient(linear, left top, left bottom, from(#efefef), to(#CDCDCD));
        background: linear-gradient(180deg, #efefef 0%, #CDCDCD 100%);
        border-radius: 50%;
        z-index: 2; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn:before {
        content: '';
        position: absolute;
        left: 2px;
        bottom: 2px;
        right: 2px;
        top: 2px;
        border: 2px solid rgba(0, 0, 0, 0.05);
        background: transparent;
        border-radius: 50%;
        z-index: 3;
        height: inherit;
        width: inherit;
        display: none; }
    .campaign-box-ui.closing-soon__item .campaign-action .cart-quantity {
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      letter-spacing: -0.52752px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      color: #000;
      height: 22px;
      width: 37px;
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border: 0;
      margin-right: 0;
      border: 0;
      background: none; }
    .campaign-box-ui.closing-soon__item .campaign-action.in-cart {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      background: #fff;
      border: 1px solid #ccc; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .add-to-cart-button {
        background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF)) !important;
        background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%) !important; }
        .campaign-box-ui.closing-soon__item .campaign-action.in-cart .add-to-cart-button span.qtyIcon {
          display: inline-block; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .cart-quantity {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .minus-from-cart-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
  .campaign-box-ui .recommended-timer {
    background: #f4f4f4;
    height: 19px;
    border-radius: 5px;
    padding: 1px 7px 5px 9px;
    width: 130px;
    margin-bottom: 11px; }
    .campaign-box-ui .recommended-timer .single-upper-bar {
      color: red;
      font-size: 10px;
      line-height: 18.397px;
      letter-spacing: -0.5px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui .recommended-timer b {
      display: inline;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif;
      font-size: 10px;
      line-height: 18.397px;
      letter-spacing: -0.5px;
      color: red; }

.campaign-box-single {
  border-radius: 36px;
  background-color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-transition: height ease-in 0.2s;
  transition: height ease-in 0.2s; }
  .campaign-box-single .campaign-image {
    height: 140px;
    margin-bottom: 18px;
    position: relative;
    text-align: center;
    margin-top: 26px;
    border-radius: 35px;
    overflow: hidden; }
    .campaign-box-single .campaign-image img {
      height: auto;
      width: 100%;
      display: block; }
  .campaign-box-single h3 {
    letter-spacing: -0.841035px;
    font-size: 18px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 54px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-single h3 b {
      display: block;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single h3 b span {
        color: #4200FF;
        font-style: normal; }
    .campaign-box-single h3 span {
      font-style: italic;
      color: #ff6262;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
  .campaign-box-single .scrollable-prize {
    margin: 0 0 5px; }
    .campaign-box-single .scrollable-prize .swiper-slide {
      border: 1px solid #e3e3e3;
      padding: 4px 8px;
      border-radius: 7px;
      letter-spacing: -0.25px;
      font-size: 12px;
      white-space: nowrap;
      text-align: left;
      width: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .campaign-box-single .scrollable-prize .swiper-slide span {
        display: block;
        color: #4200FF;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single .scrollable-prize .scrollable-wrapper {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: -webkit-grab;
      cursor: grab; }
      .campaign-box-single .scrollable-prize .scrollable-wrapper::-webkit-scrollbar {
        display: none; }
  .campaign-box-single .campaign-information {
    text-align: center;
    margin: 3px 0 5px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .campaign-box-single .campaign-information b {
      color: #4200FF;
      margin: 0;
      white-space: nowrap;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif; }
  .campaign-box-single h4 {
    letter-spacing: -0.79431px;
    font-size: 17px;
    line-height: 19px;
    margin: 0;
    display: inline-block;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
  .campaign-box-single .learn-more-link {
    border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
    margin-right: 7px;
    height: 48px;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #000;
    letter-spacing: -0.249023px;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .campaign-box-single .learn-more-link:hover {
      background-color: #efefef; }
  .campaign-box-single.soldout-campagin-box {
    padding: 11px 10px 25px 10px;
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 25px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .campaign-box-single.soldout-campagin-box h5 {
      width: 100%;
      color: #000;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif;
      font-size: 20px;
      letter-spacing: -0.2px;
      text-align: left;
      line-height: 14px;
      margin-bottom: 11px;
      padding-left: 10px; }
      .campaign-box-single.soldout-campagin-box h5 span {
        color: #EF162E; }
      .campaign-box-single.soldout-campagin-box h5 b {
        display: block;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif;
        font-size: 22px;
        letter-spacing: -0.08px;
        line-height: 24px;
        opacity: 1;
        color: #000;
        margin-bottom: 10px;
        height: 48px;
        overflow: hidden; }
    .campaign-box-single.soldout-campagin-box .campaign-image {
      position: relative;
      border-radius: 0;
      position: relative;
      overflow: visible;
      margin-bottom: 34px; }
      .campaign-box-single.soldout-campagin-box .campaign-image .combineImage {
        opacity: 1;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        border-radius: 19px; }
      .campaign-box-single.soldout-campagin-box .campaign-image .soldoutLabelImageNew {
        position: absolute;
        height: 44px;
        padding: 0 19px 0 19px;
        color: #fff;
        font-family: "ABC Ginto Nord Bold";
        font-size: 28px;
        letter-spacing: -0.5px;
        -webkit-box-shadow: 1.72px 3.43px 7.88px 0 rgba(0, 0, 0, 0.31);
                box-shadow: 1.72px 3.43px 7.88px 0 rgba(0, 0, 0, 0.31);
        background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
        border-radius: 11px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-style: italic;
        text-transform: uppercase;
        left: 10px;
        bottom: -20px; }
    .campaign-box-single.soldout-campagin-box h6 {
      font-size: 18px;
      color: #ff3e3e;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single.soldout-campagin-box .youHaveTickets {
      border: 1px solid #c1c1c1;
      border-radius: 14px;
      padding: 4px 10px;
      color: #000;
      letter-spacing: -0.43px;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: initial;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif;
      font-size: 0.7vw; }
      .campaign-box-single.soldout-campagin-box .youHaveTickets b {
        font-weight: normal;
        margin-right: 1px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .campaign-box-single.soldout-campagin-box .youHaveTickets.isdisabled {
        color: #979797;
        pointer-events: none; }
    .campaign-box-single.soldout-campagin-box .drawDateTimeSection {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 100%;
      padding-left: 10px; }
      .campaign-box-single.soldout-campagin-box .drawDateTimeSection h5 {
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.soldout-campagin-box .drawDateTimeSection h6 {
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        font-size: 20px;
        letter-spacing: -0.2px;
        display: block;
        margin: 0; }
  .campaign-box-single.draw-campagin-box {
    padding: 24px 25px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 321px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .campaign-box-single.draw-campagin-box h5 {
      width: 100%;
      color: #000;
      letter-spacing: -0.63 px;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      margin: 0 0 12px;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box h5 b {
        display: block;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box h5.getChance {
        margin: 0;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .campaign-box-single.draw-campagin-box h5.getChance b {
          color: #ff3e3e;
          font-style: italic;
          display: inline-block; }
    .campaign-box-single.draw-campagin-box .campaign-image {
      position: relative;
      margin-top: 0; }
      .campaign-box-single.draw-campagin-box .campaign-image .combineImage {
        opacity: 1;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        opacity: 0.3; }
      .campaign-box-single.draw-campagin-box .campaign-image .soldoutLabelImage {
        position: absolute;
        width: 160px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: auto; }
    .campaign-box-single.draw-campagin-box h6 {
      font-size: 18px;
      color: #ff3e3e;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single.draw-campagin-box .youHaveTickets {
      border: 1px solid #c1c1c1;
      border-radius: 14px;
      padding: 4px 10px;
      color: #000;
      letter-spacing: -0.43px;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: initial;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif;
      font-size: 0.7vw; }
      .campaign-box-single.draw-campagin-box .youHaveTickets b {
        font-weight: normal;
        margin-right: 1px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .campaign-box-single.draw-campagin-box .youHaveTickets.isdisabled {
        color: #979797;
        pointer-events: none; }
    .campaign-box-single.draw-campagin-box .draw-winner-image {
      display: none; }
    .campaign-box-single.draw-campagin-box .hideOnWinner {
      display: block; }
    .campaign-box-single.draw-campagin-box .noHideOnWinner {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .campaign-image {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .draw-winner-image {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      position: relative;
      height: 155px;
      overflow: hidden;
      padding: 0 15px; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerImage {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 86px;
      height: 86px;
      border: 5px solid #7000ff;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden; }
      .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerImage img {
        border: 3px solid #fff;
        border-radius: 50%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerAnnounceTime {
      display: block;
      border-top: 1px solid #f0f0f0;
      padding-top: 5px;
      font-size: 10px;
      letter-spacing: -0.25px;
      color: #979797;
      text-align: center; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .hideOnWinner {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .noHideOnWinner {
      display: block; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .js-see-all-winner-desktop {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .campaign-box-single.draw-campagin-box .additionalWinner-Box {
      padding: 18px 0 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 81%;
      background: #fff;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      overflow: hidden;
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      height: 0;
      opacity: 0;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      z-index: -1; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box.active {
        opacity: 1;
        height: auto;
        z-index: 1; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box img {
        left: 0;
        right: 0;
        position: absolute; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box img.top {
          top: 0; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box img.bottom {
          bottom: 75px; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li {
          border-bottom: 1px solid #efefef;
          padding-bottom: 12px;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 15px; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li:last-child {
            border-bottom: 0;
            padding-bottom: 0; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-name {
            letter-spacing: -0.63px;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            font-weight: 400;
            font-family: 'Galano Grotesque Medium', sans-serif; }
            .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-name span {
              color: #4200FF; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .on-winning {
            letter-spacing: -0.63px;
            font-size: 14px;
            line-height: 15px;
            text-align: center; }
            .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .on-winning b {
              font-weight: 400;
              font-family: 'Galano Grotesque Medium', sans-serif; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-ticket {
            font-size: 8px;
            line-height: 11px;
            letter-spacing: -0.151111px;
            text-align: center; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .winner-link-video {
        width: auto;
        padding: 0 13px; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .close-winner-box {
        background: #fff;
        padding: 12px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        overflow: hidden;
        border: 1px solid #efefef; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .scrolled {
        overflow-x: scroll;
        max-height: 263px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */ }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box .scrolled::-webkit-scrollbar {
          display: none; }
    .campaign-box-single.draw-campagin-box .winner-link-video {
      font-size: 13px;
      line-height: 13px;
      letter-spacing: -0.43px;
      text-align: center;
      color: #000;
      width: 150px;
      height: 29px;
      border-radius: 14px;
      border: 1px solid #c1c1c1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      padding: 0 15px;
      margin: 10px auto;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box .winner-link-video span {
        background: rgba(120, 120, 120, 0.470252);
        width: 23px;
        height: 23px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-radius: 50%;
        margin-right: 5px;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s; }
        .campaign-box-single.draw-campagin-box .winner-link-video span img {
          width: 8.8px; }
    .campaign-box-single.draw-campagin-box .js-see-all-winner-desktop {
      display: none; }
  .campaign-box-single .campaign-action {
    margin-bottom: 12px; }
  .campaign-box-single .endDateBox {
    color: #9b9b9b;
    font-size: 10px;
    display: block;
    margin: 8px 0;
    text-align: center;
    line-height: 12px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-single .endDateBox.last {
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 5px 0; }
  .campaign-box-single .dsfNewCampaign {
    font-size: 10px;
    color: #000;
    letter-spacing: -0.26px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 8px auto 3px;
    max-width: 286px;
    padding: 0;
    height: 35px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif;
    line-height: 10px; }
    .campaign-box-single .dsfNewCampaign img {
      margin-left: 5px;
      max-height: 35px; }
    .campaign-box-single .dsfNewCampaign.noLine {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    .campaign-box-single .dsfNewCampaign b {
      font-weight: normal;
      margin-left: 3px;
      font-family: "Rubik", sans-serif !important;
      font-weight: 500 !important; }
  .campaign-box-single .action-icon {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 10px;
    top: auto;
    right: 13px;
    direction: ltr; }
    .campaign-box-single .action-icon li {
      font-size: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      .campaign-box-single .action-icon li:last-child {
        margin-top: 12px; }
      .campaign-box-single .action-icon li .earlybird-icon {
        font-size: 25px;
        color: #f6c161;
        height: 26px; }
    .campaign-box-single .action-icon .wishlist-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: right;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer;
      color: #000;
      font-size: 20px;
      margin-top: 0;
      background: url(../images/m/icons/wishlist-icon-new.png) no-repeat center top;
      background-size: 100%;
      width: 28px;
      height: 25px; }
      .campaign-box-single .action-icon .wishlist-icon.added {
        background: url(../images/m/icons/wishlist-icon-new-fill.png) no-repeat center top;
        background-size: 100%; }
    .campaign-box-single .action-icon li .earlybird-icon {
      margin-right: 2px; }
    .campaign-box-single .action-icon .wishlist-icon {
      margin-right: 5px; }
  .campaign-box-single .hoveredBox {
    height: 0;
    -webkit-transition: height ease-in 0.2s;
    transition: height ease-in 0.2s;
    overflow: hidden; }
  .campaign-box-single .campaign-sale-action {
    height: 48px;
    border-radius: 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    letter-spacing: -0.249023px;
    font-size: 15px;
    line-height: 18px;
    background: #e8e8e8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 12px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .campaign-box-single .drawDateTimeSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 7px; }
    .campaign-box-single .drawDateTimeSection h5 {
      display: inline-block;
      padding: 0;
      white-space: nowrap;
      margin: 0; }
    .campaign-box-single .drawDateTimeSection h6 {
      display: inline-block;
      padding: 0;
      white-space: normal;
      margin: 0;
      text-align: left;
      line-height: 20px; }
    .campaign-box-single .drawDateTimeSection h5 {
      margin-right: 3px; }
    .campaign-box-single .drawDateTimeSection h6 {
      margin-top: 3px; }
  .campaign-box-single .newPartnerLogo {
    top: 20px;
    left: 48px;
    position: absolute;
    width: 90px; }

.draw-title-header {
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  height: 105px;
  background: #f63536;
  position: relative;
  overflow: hidden; }
  .draw-title-header.winnerSwitch {
    background: #7000ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .draw-title-header.winnerSwitch .endedHoldTight {
      display: none; }
    .draw-title-header.winnerSwitch .winnerBlock {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .draw-title-header.winnerSwitch .winnerBlock header {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.06);
        height: 51px;
        border-top-left-radius: 36px;
        border-top-right-radius: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        letter-spacing: -0.92px;
        font-size: 25px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info {
        padding-top: 51px; }
        .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info h5 {
          color: #fff;
          letter-spacing: -0.63px;
          font-size: 18px;
          line-height: 19px;
          text-align: center;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
        .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info h6 {
          color: #fff;
          letter-spacing: -0.63px;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          font-weight: 400;
          font-family: 'Galano Grotesque', sans-serif; }
    .draw-title-header.winnerSwitch.hide {
      display: none; }
  .draw-title-header.noWinnerAnnounce {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .draw-title-header.noWinnerAnnounce.hide {
      display: none; }
  .draw-title-header.congratsHeader {
    background: #4ac052; }
  .draw-title-header.hold {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
    .draw-title-header.hold.winnerSwitch {
      background: #f63536; }
      .draw-title-header.hold.winnerSwitch .endedHoldTight {
        display: block; }
      .draw-title-header.hold.winnerSwitch .winnerBlock {
        display: none; }
  .draw-title-header.getset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
  .draw-title-header.getready {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
  .draw-title-header .holdTight {
    letter-spacing: -0.43px;
    line-height: 15px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    display: none;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .holdTight.show {
      display: block;
      -webkit-animation: blinker 2s linear infinite;
              animation: blinker 2s linear infinite; }
  .draw-title-header .countDowns {
    color: #fff;
    font-size: 116px;
    -webkit-transform: scale(0);
            transform: scale(0);
    line-height: 112px;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .countDowns.show {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
  .draw-title-header .timeremaining {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    font-size: 42px;
    line-height: 27px;
    letter-spacing: -3.2px;
    padding-top: 43px;
    width: 200px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .timeremaining > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 10px;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .draw-title-header .timeremaining > div:first-child {
        margin-left: 0; }
      .draw-title-header .timeremaining > div:last-child .dayLabel {
        margin-left: 0; }
    .draw-title-header .timeremaining .dayLabel {
      font-size: 13px;
      letter-spacing: -0.52px;
      margin-left: -12px;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .draw-title-header .timeremaining .seperator {
      margin: 0 0 0 10px; }
  .draw-title-header .drawHappening {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.06);
    height: 33px;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    letter-spacing: -0.43px;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .draw-title-header .liveDrawAnnouncement {
    color: #fff;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.63px;
    margin: 0;
    height: 72px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 33px 10px 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }

.featured-campaign .campaign-box {
  border-radius: 36px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  height: 100%;
  -moz-transition: all ease 6s;
  -o-transition: all ease 6s;
  -webkit-transition: all ease 6s;
  transition: all ease 6s; }

.featured-campaign .bg-overlay {
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(43.01%, rgba(0, 0, 0, 0.0001)), color-stop(117.33%, #212121));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 43.01%, #212121 117.33%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 36px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.featured-campaign .campaign-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.featured-campaign h2 {
  color: #ff6262;
  letter-spacing: -5.04621px;
  font-size: 90px;
  line-height: 68px;
  font-style: italic;
  font-weight: 400;
  font-family: 'Galano Grotesque Bold', sans-serif; }

.featured-campaign h3 {
  color: #ffffff;
  letter-spacing: -1.44845px;
  font-size: 31px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: 'Galano Grotesque Bold', sans-serif; }

.featured-campaign h4 {
  letter-spacing: -1.30828px;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  width: 50%;
  font-weight: 400;
  font-family: 'Galano Grotesque', sans-serif; }

.featured-campaign .learn-more-link {
  width: 147px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 12px; }

.featured-campaign:hover .campaign-box {
  background-size: 115%; }

.dedTabSwitch {
  background: #ebebeb;
  height: 36px;
  max-width: 343px;
  width: 100%;
  border-radius: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #ebebeb;
  position: absolute;
  left: 50%;
  top: 55px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 3; }
  .dedTabSwitch a {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.52px;
    width: 50%;
    border-radius: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    color: #000; }
    .dedTabSwitch a.active {
      background: #fff;
      color: #312dff;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }

.draw-campaign-area .emptyDedScreen {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 90%;
  margin: 200px auto; }
  .draw-campaign-area .emptyDedScreen.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .draw-campaign-area .emptyDedScreen p {
    text-align: center;
    width: 100%;
    max-width: 100%; }

.emptyBox {
  background: #fcfcfc;
  border-radius: 25px;
  height: 426px;
  overflow: hidden; }
  .emptyBox .emptyBox-header {
    background-color: #f5f5f5;
    height: 105px; }
  .emptyBox .emptyBox-header2 {
    height: 51px;
    background: #efefef; }
  .emptyBox .emptyBox-image {
    background: #f7f7f7;
    width: 231px;
    height: 113px;
    border-radius: 23px;
    margin-top: 52px;
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 13px;
    letter-spacing: -0.52px;
    color: #bababa;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 40px;
    text-align: center; }
  .emptyBox .emptyBox-text {
    background-color: #e2e2e2;
    border-radius: 17px;
    margin: 0 auto;
    height: 15px; }
    .emptyBox .emptyBox-text.firstText {
      width: 140px;
      margin-bottom: 5px; }
    .emptyBox .emptyBox-text.secondText {
      width: 232px;
      margin-bottom: 21px; }
    .emptyBox .emptyBox-text.thirdText {
      width: 174px;
      background: #f1f1f1; }

.commonBanner {
  border-radius: 30px;
  height: 100%;
  overflow: hidden; }
  .commonBanner h2 {
    letter-spacing: -1.44845px;
    font-size: 31px;
    line-height: 27px;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif;
    line-height: 36px; }
  .commonBanner .features-banner {
    margin: 0 0 30px;
    padding: 0; }
    .commonBanner .features-banner li {
      letter-spacing: -0.43875px;
      font-size: 15px;
      line-height: 19px;
      color: #ffffff;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
  .commonBanner .content-left {
    width: 320px; }
    .commonBanner .content-left h2 {
      margin-bottom: 5px; }
  .commonBanner.invite .content-left {
    width: auto;
    margin-left: 30%;
    margin-top: 30px; }
    .commonBanner.invite .content-left h2 {
      line-height: 30px; }
    .commonBanner.invite .content-left p {
      color: #fff;
      margin-bottom: 20px; }
    .commonBanner.invite .content-left .normal-link {
      border: 1px solid rgba(255, 255, 255, 0.613227);
      border-radius: 15px;
      height: 52px;
      width: 191px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #fff;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }

.campaign-box-ui {
  background: #ffffff;
  -webkit-box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.04);
          box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.04);
  height: auto;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: -webkit-transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s; }
  .campaign-box-ui .sold-out-bar {
    width: 100%;
    margin-bottom: 5px; }
    .campaign-box-ui .sold-out-bar .sold-out-indicator {
      height: 9px;
      margin-bottom: 2px; }
      .campaign-box-ui .sold-out-bar .sold-out-indicator span {
        height: 9px; }
    .campaign-box-ui .sold-out-bar p {
      text-align: left; }
  .campaign-box-ui .closing-soon-copy-section {
    padding: 6px 14px 17px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%; }
    .campaign-box-ui .closing-soon-copy-section .closing-soon__item-title {
      height: 30px;
      overflow: hidden; }
  .campaign-box-ui .image-container {
    margin-bottom: 4px;
    min-height: 104px;
    overflow: hidden; }
    .campaign-box-ui .image-container img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; }
  .campaign-box-ui h3 {
    letter-spacing: -0.814286px;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin: 0;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-ui h3 b {
      font-family: "ABC Ginto Nord Bold Italic";
      background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      line-height: 95px;
      font-size: 90px;
      margin-bottom: -15px;
      display: inline-block;
      font-size: 17px;
      line-height: 19px;
      text-transform: capitalize;
      padding-right: 10px; }
  .campaign-box-ui h2 {
    letter-spacing: -0.495px;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    margin: 0 0 7px;
    width: 100%;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .campaign-box-ui .add-to-cart-button {
    overflow: hidden;
    padding: 0;
    width: 100%; }
    .campaign-box-ui .add-to-cart-button.remove-to-cart {
      background: #ff6262;
      color: #ffffff;
      border-color: #ff6262; }
  .campaign-box-ui .clossing-soon__cart-actions {
    position: relative;
    height: 29px;
    padding: 4px 0;
    margin: 0 0 0 0;
    position: relative; }
    .campaign-box-ui .clossing-soon__cart-actions h4 {
      font-size: 9px;
      line-height: 8px;
      letter-spacing: -0.52px;
      color: #4f4f4f;
      margin: 0;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      text-align: left; }
    .campaign-box-ui .clossing-soon__cart-actions h5 {
      font-size: 13px;
      line-height: 14px;
      letter-spacing: -0.39px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif;
      margin: 0;
      text-align: left;
      color: #4200FF; }
    .campaign-box-ui .clossing-soon__cart-actions .plus-icon {
      background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
      background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .campaign-box-ui .clossing-soon__cart-actions .plus-icon svg {
        margin-top: 2px; }
  .campaign-box-ui .minus-from-cart-btn:hover {
    background: #e7e7e7; }
  .campaign-box-ui.product-ui {
    height: auto;
    padding: 0 0 15px;
    overflow: hidden; }
    .campaign-box-ui.product-ui h3 {
      font-size: 18px;
      line-height: 17px;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui.product-ui h2 {
      font-size: 15px;
      line-height: 17px;
      letter-spacing: -0.7px;
      color: #48b650;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui.product-ui .image-container {
      text-align: center;
      margin-bottom: 19px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 320px;
      overflow: hidden;
      position: relative; }
      .campaign-box-ui.product-ui .image-container img {
        margin: 0 auto;
        max-height: auto;
        width: 100%;
        height: auto; }
      .campaign-box-ui.product-ui .image-container .modesh-logo {
        width: 40px;
        position: absolute;
        left: 15px;
        top: 15px; }
      .campaign-box-ui.product-ui .image-container.alignCenter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
  .campaign-box-ui.closing-soon__item .campaign-action {
    position: absolute;
    right: -7px;
    bottom: -2px;
    padding: 2px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 40px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #fff; }
    .campaign-box-ui.closing-soon__item .campaign-action .cart-quantity {
      pointer-events: none; }
    .campaign-box-ui.closing-soon__item .campaign-action .add-to-cart-button {
      background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
      background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .campaign-box-ui.closing-soon__item .campaign-action .add-to-cart-button svg {
        margin-top: 2px; }
    .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: transparent;
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      overflow: hidden;
      position: relative;
      margin-right: 0;
      border: 0; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn span {
        position: relative;
        z-index: 4;
        height: 20px;
        margin-left: 0; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background: -webkit-gradient(linear, left top, left bottom, from(#efefef), to(#CDCDCD));
        background: linear-gradient(180deg, #efefef 0%, #CDCDCD 100%);
        border-radius: 50%;
        z-index: 2; }
      .campaign-box-ui.closing-soon__item .campaign-action .minus-from-cart-btn:before {
        content: '';
        position: absolute;
        left: 2px;
        bottom: 2px;
        right: 2px;
        top: 2px;
        border: 2px solid rgba(0, 0, 0, 0.05);
        background: transparent;
        border-radius: 50%;
        z-index: 3;
        height: inherit;
        width: inherit;
        display: none; }
    .campaign-box-ui.closing-soon__item .campaign-action .cart-quantity {
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      letter-spacing: -0.52752px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      color: #000;
      height: 22px;
      width: 37px;
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border: 0;
      margin-right: 0;
      border: 0;
      background: none; }
    .campaign-box-ui.closing-soon__item .campaign-action.in-cart {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      background: #fff;
      border: 1px solid #ccc; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .add-to-cart-button {
        background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF)) !important;
        background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%) !important; }
        .campaign-box-ui.closing-soon__item .campaign-action.in-cart .add-to-cart-button span.qtyIcon {
          display: inline-block; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .cart-quantity {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .campaign-box-ui.closing-soon__item .campaign-action.in-cart .minus-from-cart-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
  .campaign-box-ui .recommended-timer {
    background: #f4f4f4;
    height: 19px;
    border-radius: 5px;
    padding: 1px 7px 5px 9px;
    width: 130px;
    margin-bottom: 11px; }
    .campaign-box-ui .recommended-timer .single-upper-bar {
      color: red;
      font-size: 10px;
      line-height: 18.397px;
      letter-spacing: -0.5px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-ui .recommended-timer b {
      display: inline;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif;
      font-size: 10px;
      line-height: 18.397px;
      letter-spacing: -0.5px;
      color: red; }

.campaign-box-single {
  border-radius: 36px;
  background-color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-transition: height ease-in 0.2s;
  transition: height ease-in 0.2s; }
  .campaign-box-single .campaign-image {
    height: 140px;
    margin-bottom: 18px;
    position: relative;
    text-align: center;
    margin-top: 26px;
    border-radius: 35px;
    overflow: hidden; }
    .campaign-box-single .campaign-image img {
      height: auto;
      width: 100%;
      display: block; }
  .campaign-box-single h3 {
    letter-spacing: -0.841035px;
    font-size: 18px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 54px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-single h3 b {
      display: block;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single h3 b span {
        color: #4200FF;
        font-style: normal; }
    .campaign-box-single h3 span {
      font-style: italic;
      color: #ff6262;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
  .campaign-box-single .scrollable-prize {
    margin: 0 0 5px; }
    .campaign-box-single .scrollable-prize .swiper-slide {
      border: 1px solid #e3e3e3;
      padding: 4px 8px;
      border-radius: 7px;
      letter-spacing: -0.25px;
      font-size: 12px;
      white-space: nowrap;
      text-align: left;
      width: auto;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .campaign-box-single .scrollable-prize .swiper-slide span {
        display: block;
        color: #4200FF;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single .scrollable-prize .scrollable-wrapper {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: -webkit-grab;
      cursor: grab; }
      .campaign-box-single .scrollable-prize .scrollable-wrapper::-webkit-scrollbar {
        display: none; }
  .campaign-box-single .campaign-information {
    text-align: center;
    margin: 3px 0 5px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .campaign-box-single .campaign-information b {
      color: #4200FF;
      margin: 0;
      white-space: nowrap;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif; }
  .campaign-box-single h4 {
    letter-spacing: -0.79431px;
    font-size: 17px;
    line-height: 19px;
    margin: 0;
    display: inline-block;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
  .campaign-box-single .learn-more-link {
    border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 50%;
    margin-right: 7px;
    height: 48px;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #000;
    letter-spacing: -0.249023px;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .campaign-box-single .learn-more-link:hover {
      background-color: #efefef; }
  .campaign-box-single.soldout-campagin-box {
    padding: 11px 10px 25px 10px;
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 25px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .campaign-box-single.soldout-campagin-box h5 {
      width: 100%;
      color: #000;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif;
      font-size: 20px;
      letter-spacing: -0.2px;
      text-align: left;
      line-height: 14px;
      margin-bottom: 11px;
      padding-left: 10px; }
      .campaign-box-single.soldout-campagin-box h5 span {
        color: #EF162E; }
      .campaign-box-single.soldout-campagin-box h5 b {
        display: block;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif;
        font-size: 22px;
        letter-spacing: -0.08px;
        line-height: 24px;
        opacity: 1;
        color: #000;
        margin-bottom: 10px;
        height: 48px;
        overflow: hidden; }
    .campaign-box-single.soldout-campagin-box .campaign-image {
      position: relative;
      border-radius: 0;
      position: relative;
      overflow: visible;
      margin-bottom: 34px; }
      .campaign-box-single.soldout-campagin-box .campaign-image .combineImage {
        opacity: 1;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        border-radius: 19px; }
      .campaign-box-single.soldout-campagin-box .campaign-image .soldoutLabelImageNew {
        position: absolute;
        height: 44px;
        padding: 0 19px 0 19px;
        color: #fff;
        font-family: "ABC Ginto Nord Bold";
        font-size: 28px;
        letter-spacing: -0.5px;
        -webkit-box-shadow: 1.72px 3.43px 7.88px 0 rgba(0, 0, 0, 0.31);
                box-shadow: 1.72px 3.43px 7.88px 0 rgba(0, 0, 0, 0.31);
        background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
        border-radius: 11px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-style: italic;
        text-transform: uppercase;
        left: 10px;
        bottom: -20px; }
    .campaign-box-single.soldout-campagin-box h6 {
      font-size: 18px;
      color: #ff3e3e;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single.soldout-campagin-box .youHaveTickets {
      border: 1px solid #c1c1c1;
      border-radius: 14px;
      padding: 4px 10px;
      color: #000;
      letter-spacing: -0.43px;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: initial;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif;
      font-size: 0.7vw; }
      .campaign-box-single.soldout-campagin-box .youHaveTickets b {
        font-weight: normal;
        margin-right: 1px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .campaign-box-single.soldout-campagin-box .youHaveTickets.isdisabled {
        color: #979797;
        pointer-events: none; }
    .campaign-box-single.soldout-campagin-box .drawDateTimeSection {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 100%;
      padding-left: 10px; }
      .campaign-box-single.soldout-campagin-box .drawDateTimeSection h5 {
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.soldout-campagin-box .drawDateTimeSection h6 {
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        font-size: 20px;
        letter-spacing: -0.2px;
        display: block;
        margin: 0; }
  .campaign-box-single.draw-campagin-box {
    padding: 24px 25px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 321px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .campaign-box-single.draw-campagin-box h5 {
      width: 100%;
      color: #000;
      letter-spacing: -0.63 px;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      margin: 0 0 12px;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box h5 b {
        display: block;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box h5.getChance {
        margin: 0;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .campaign-box-single.draw-campagin-box h5.getChance b {
          color: #ff3e3e;
          font-style: italic;
          display: inline-block; }
    .campaign-box-single.draw-campagin-box .campaign-image {
      position: relative;
      margin-top: 0; }
      .campaign-box-single.draw-campagin-box .campaign-image .combineImage {
        opacity: 1;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        opacity: 0.3; }
      .campaign-box-single.draw-campagin-box .campaign-image .soldoutLabelImage {
        position: absolute;
        width: 160px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: auto; }
    .campaign-box-single.draw-campagin-box h6 {
      font-size: 18px;
      color: #ff3e3e;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .campaign-box-single.draw-campagin-box .youHaveTickets {
      border: 1px solid #c1c1c1;
      border-radius: 14px;
      padding: 4px 10px;
      color: #000;
      letter-spacing: -0.43px;
      width: auto;
      margin: 0 auto;
      cursor: pointer;
      pointer-events: initial;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif;
      font-size: 0.7vw; }
      .campaign-box-single.draw-campagin-box .youHaveTickets b {
        font-weight: normal;
        margin-right: 1px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .campaign-box-single.draw-campagin-box .youHaveTickets.isdisabled {
        color: #979797;
        pointer-events: none; }
    .campaign-box-single.draw-campagin-box .draw-winner-image {
      display: none; }
    .campaign-box-single.draw-campagin-box .hideOnWinner {
      display: block; }
    .campaign-box-single.draw-campagin-box .noHideOnWinner {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .campaign-image {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .draw-winner-image {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      position: relative;
      height: 155px;
      overflow: hidden;
      padding: 0 15px; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerImage {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 86px;
      height: 86px;
      border: 5px solid #7000ff;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden; }
      .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerImage img {
        border: 3px solid #fff;
        border-radius: 50%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .winnerAnnounceTime {
      display: block;
      border-top: 1px solid #f0f0f0;
      padding-top: 5px;
      font-size: 10px;
      letter-spacing: -0.25px;
      color: #979797;
      text-align: center; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .hideOnWinner {
      display: none; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .noHideOnWinner {
      display: block; }
    .campaign-box-single.draw-campagin-box.winnerAnnounced .js-see-all-winner-desktop {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .campaign-box-single.draw-campagin-box .additionalWinner-Box {
      padding: 18px 0 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 81%;
      background: #fff;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      overflow: hidden;
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      height: 0;
      opacity: 0;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      z-index: -1; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box.active {
        opacity: 1;
        height: auto;
        z-index: 1; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box img {
        left: 0;
        right: 0;
        position: absolute; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box img.top {
          top: 0; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box img.bottom {
          bottom: 75px; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li {
          border-bottom: 1px solid #efefef;
          padding-bottom: 12px;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 15px; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li:last-child {
            border-bottom: 0;
            padding-bottom: 0; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-name {
            letter-spacing: -0.63px;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            font-weight: 400;
            font-family: 'Galano Grotesque Medium', sans-serif; }
            .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-name span {
              color: #4200FF; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .on-winning {
            letter-spacing: -0.63px;
            font-size: 14px;
            line-height: 15px;
            text-align: center; }
            .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .on-winning b {
              font-weight: 400;
              font-family: 'Galano Grotesque Medium', sans-serif; }
          .campaign-box-single.draw-campagin-box .additionalWinner-Box ul li .winner-ticket {
            font-size: 8px;
            line-height: 11px;
            letter-spacing: -0.151111px;
            text-align: center; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .winner-link-video {
        width: auto;
        padding: 0 13px; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .close-winner-box {
        background: #fff;
        padding: 12px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        overflow: hidden;
        border: 1px solid #efefef; }
      .campaign-box-single.draw-campagin-box .additionalWinner-Box .scrolled {
        overflow-x: scroll;
        max-height: 263px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */ }
        .campaign-box-single.draw-campagin-box .additionalWinner-Box .scrolled::-webkit-scrollbar {
          display: none; }
    .campaign-box-single.draw-campagin-box .winner-link-video {
      font-size: 13px;
      line-height: 13px;
      letter-spacing: -0.43px;
      text-align: center;
      color: #000;
      width: 150px;
      height: 29px;
      border-radius: 14px;
      border: 1px solid #c1c1c1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      padding: 0 15px;
      margin: 10px auto;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .campaign-box-single.draw-campagin-box .winner-link-video span {
        background: rgba(120, 120, 120, 0.470252);
        width: 23px;
        height: 23px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-radius: 50%;
        margin-right: 5px;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s; }
        .campaign-box-single.draw-campagin-box .winner-link-video span img {
          width: 8.8px; }
    .campaign-box-single.draw-campagin-box .js-see-all-winner-desktop {
      display: none; }
  .campaign-box-single .campaign-action {
    margin-bottom: 12px; }
  .campaign-box-single .endDateBox {
    color: #9b9b9b;
    font-size: 10px;
    display: block;
    margin: 8px 0;
    text-align: center;
    line-height: 12px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .campaign-box-single .endDateBox.last {
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 5px 0; }
  .campaign-box-single .dsfNewCampaign {
    font-size: 10px;
    color: #000;
    letter-spacing: -0.26px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 8px auto 3px;
    max-width: 286px;
    padding: 0;
    height: 35px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif;
    line-height: 10px; }
    .campaign-box-single .dsfNewCampaign img {
      margin-left: 5px;
      max-height: 35px; }
    .campaign-box-single .dsfNewCampaign.noLine {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    .campaign-box-single .dsfNewCampaign b {
      font-weight: normal;
      margin-left: 3px;
      font-family: "Rubik", sans-serif !important;
      font-weight: 500 !important; }
  .campaign-box-single .action-icon {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 10px;
    top: auto;
    right: 13px;
    direction: ltr; }
    .campaign-box-single .action-icon li {
      font-size: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      .campaign-box-single .action-icon li:last-child {
        margin-top: 12px; }
      .campaign-box-single .action-icon li .earlybird-icon {
        font-size: 25px;
        color: #f6c161;
        height: 26px; }
    .campaign-box-single .action-icon .wishlist-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: right;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      cursor: pointer;
      color: #000;
      font-size: 20px;
      margin-top: 0;
      background: url(../images/m/icons/wishlist-icon-new.png) no-repeat center top;
      background-size: 100%;
      width: 28px;
      height: 25px; }
      .campaign-box-single .action-icon .wishlist-icon.added {
        background: url(../images/m/icons/wishlist-icon-new-fill.png) no-repeat center top;
        background-size: 100%; }
    .campaign-box-single .action-icon li .earlybird-icon {
      margin-right: 2px; }
    .campaign-box-single .action-icon .wishlist-icon {
      margin-right: 5px; }
  .campaign-box-single .hoveredBox {
    height: 0;
    -webkit-transition: height ease-in 0.2s;
    transition: height ease-in 0.2s;
    overflow: hidden; }
  .campaign-box-single .campaign-sale-action {
    height: 48px;
    border-radius: 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    letter-spacing: -0.249023px;
    font-size: 15px;
    line-height: 18px;
    background: #e8e8e8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 12px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .campaign-box-single .drawDateTimeSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 7px; }
    .campaign-box-single .drawDateTimeSection h5 {
      display: inline-block;
      padding: 0;
      white-space: nowrap;
      margin: 0; }
    .campaign-box-single .drawDateTimeSection h6 {
      display: inline-block;
      padding: 0;
      white-space: normal;
      margin: 0;
      text-align: left;
      line-height: 20px; }
    .campaign-box-single .drawDateTimeSection h5 {
      margin-right: 3px; }
    .campaign-box-single .drawDateTimeSection h6 {
      margin-top: 3px; }
  .campaign-box-single .newPartnerLogo {
    top: 20px;
    left: 48px;
    position: absolute;
    width: 90px; }

.draw-title-header {
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  height: 105px;
  background: #f63536;
  position: relative;
  overflow: hidden; }
  .draw-title-header.winnerSwitch {
    background: #7000ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .draw-title-header.winnerSwitch .endedHoldTight {
      display: none; }
    .draw-title-header.winnerSwitch .winnerBlock {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .draw-title-header.winnerSwitch .winnerBlock header {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.06);
        height: 51px;
        border-top-left-radius: 36px;
        border-top-right-radius: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        letter-spacing: -0.92px;
        font-size: 25px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
      .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info {
        padding-top: 51px; }
        .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info h5 {
          color: #fff;
          letter-spacing: -0.63px;
          font-size: 18px;
          line-height: 19px;
          text-align: center;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
        .draw-title-header.winnerSwitch .winnerBlock .drawWinner-info h6 {
          color: #fff;
          letter-spacing: -0.63px;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          font-weight: 400;
          font-family: 'Galano Grotesque', sans-serif; }
    .draw-title-header.winnerSwitch.hide {
      display: none; }
  .draw-title-header.noWinnerAnnounce {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .draw-title-header.noWinnerAnnounce.hide {
      display: none; }
  .draw-title-header.congratsHeader {
    background: #4ac052; }
  .draw-title-header.hold {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
    .draw-title-header.hold.winnerSwitch {
      background: #f63536; }
      .draw-title-header.hold.winnerSwitch .endedHoldTight {
        display: block; }
      .draw-title-header.hold.winnerSwitch .winnerBlock {
        display: none; }
  .draw-title-header.getset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
  .draw-title-header.getready {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f63536; }
  .draw-title-header .holdTight {
    letter-spacing: -0.43px;
    line-height: 15px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    display: none;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .holdTight.show {
      display: block;
      -webkit-animation: blinker 2s linear infinite;
              animation: blinker 2s linear infinite; }
  .draw-title-header .countDowns {
    color: #fff;
    font-size: 116px;
    -webkit-transform: scale(0);
            transform: scale(0);
    line-height: 112px;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .countDowns.show {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
  .draw-title-header .timeremaining {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    font-size: 42px;
    line-height: 27px;
    letter-spacing: -3.2px;
    padding-top: 43px;
    width: 200px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .draw-title-header .timeremaining > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 10px;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      .draw-title-header .timeremaining > div:first-child {
        margin-left: 0; }
      .draw-title-header .timeremaining > div:last-child .dayLabel {
        margin-left: 0; }
    .draw-title-header .timeremaining .dayLabel {
      font-size: 13px;
      letter-spacing: -0.52px;
      margin-left: -12px;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .draw-title-header .timeremaining .seperator {
      margin: 0 0 0 10px; }
  .draw-title-header .drawHappening {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.06);
    height: 33px;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    letter-spacing: -0.43px;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .draw-title-header .liveDrawAnnouncement {
    color: #fff;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.63px;
    margin: 0;
    height: 72px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 33px 10px 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }

@keyframes blinker {
  50% {
    opacity: 0; } }

.featured-campaign .campaign-box {
  border-radius: 36px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  height: 100%;
  -moz-transition: all ease 6s;
  -o-transition: all ease 6s;
  -webkit-transition: all ease 6s;
  transition: all ease 6s; }

.featured-campaign .bg-overlay {
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(43.01%, rgba(0, 0, 0, 0.0001)), color-stop(117.33%, #212121));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 43.01%, #212121 117.33%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 36px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.featured-campaign .campaign-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.featured-campaign h2 {
  color: #ff6262;
  letter-spacing: -5.04621px;
  font-size: 90px;
  line-height: 68px;
  font-style: italic;
  font-weight: 400;
  font-family: 'Galano Grotesque Bold', sans-serif; }

.featured-campaign h3 {
  color: #ffffff;
  letter-spacing: -1.44845px;
  font-size: 31px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: 'Galano Grotesque Bold', sans-serif; }

.featured-campaign h4 {
  letter-spacing: -1.30828px;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  width: 50%;
  font-weight: 400;
  font-family: 'Galano Grotesque', sans-serif; }

.featured-campaign .learn-more-link {
  width: 147px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 12px; }

.featured-campaign:hover .campaign-box {
  background-size: 115%; }

.dedTabSwitch {
  background: #ebebeb;
  height: 36px;
  max-width: 343px;
  width: 100%;
  border-radius: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #ebebeb;
  position: absolute;
  left: 50%;
  top: 55px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 3; }
  .dedTabSwitch a {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.52px;
    width: 50%;
    border-radius: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    color: #000; }
    .dedTabSwitch a.active {
      background: #fff;
      color: #312dff;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }

.draw-campaign-area .emptyDedScreen {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 90%;
  margin: 200px auto; }
  .draw-campaign-area .emptyDedScreen.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .draw-campaign-area .emptyDedScreen p {
    text-align: center;
    width: 100%;
    max-width: 100%; }

.emptyBox {
  background: #fcfcfc;
  border-radius: 25px;
  height: 426px;
  overflow: hidden; }
  .emptyBox .emptyBox-header {
    background-color: #f5f5f5;
    height: 105px; }
  .emptyBox .emptyBox-header2 {
    height: 51px;
    background: #efefef; }
  .emptyBox .emptyBox-image {
    background: #f7f7f7;
    width: 231px;
    height: 113px;
    border-radius: 23px;
    margin-top: 52px;
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 13px;
    letter-spacing: -0.52px;
    color: #bababa;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 40px;
    text-align: center; }
  .emptyBox .emptyBox-text {
    background-color: #e2e2e2;
    border-radius: 17px;
    margin: 0 auto;
    height: 15px; }
    .emptyBox .emptyBox-text.firstText {
      width: 140px;
      margin-bottom: 5px; }
    .emptyBox .emptyBox-text.secondText {
      width: 232px;
      margin-bottom: 21px; }
    .emptyBox .emptyBox-text.thirdText {
      width: 174px;
      background: #f1f1f1; }

.live-section {
  min-height: 70px;
  margin-bottom: -41px; }

#main-campagin-area {
  margin: 0 36px 0 26px;
  padding: 0 0 45px; }
  #main-campagin-area .section-title {
    margin: 0 0 16px 10px; }
  #main-campagin-area .swiper-container {
    overflow: visible;
    padding: 0 0 0 10px; }
  #main-campagin-area .swiper-slide.featured {
    width: 708px; }
  #main-campagin-area .swiper-slide.single {
    width: 340px; }
    #main-campagin-area .swiper-slide.single .campaign-box-single {
      height: 402px;
      padding: 42px 17px 0; }
  #main-campagin-area .swiper-slide.featured-campaign {
    width: 708px; }
    #main-campagin-area .swiper-slide.featured-campaign .campaign-box {
      height: 402px; }
  #main-campagin-area .swiper-slide.timeLimited .campaign-box-single {
    height: 361px;
    padding: 0 17px; }
  #main-campagin-area .swiper-slide.timeLimited:hover .campaign-box-single {
    top: 41px;
    min-height: 361px; }
  #main-campagin-area .swiper-slide.swiper-slide-visible.featured-campaign h2,
  #main-campagin-area .swiper-slide.swiper-slide-visible.featured-campaign h3,
  #main-campagin-area .swiper-slide.swiper-slide-visible.featured-campaign .campaign-information {
    -webkit-animation: fadeInUp;
            animation: fadeInUp;
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
  #main-campagin-area .swiper-slide .campaign-box-single {
    -webkit-transition: -webkit-transform ease-in-out 0.3s;
    transition: -webkit-transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s; }
  #main-campagin-area .swiper-slide:hover .campaign-box-single {
    height: auto;
    min-height: 402px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
            transform: scale3d(1.02, 1.02, 1.02); }
    #main-campagin-area .swiper-slide:hover .campaign-box-single .hoveredBox {
      height: auto;
      margin-bottom: 10px; }
  #main-campagin-area .big-box {
    margin-bottom: 29px;
    position: relative;
    -webkit-transition: -webkit-transform ease-in-out 0.3s;
    transition: -webkit-transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
    z-index: 2; }
    #main-campagin-area .big-box .idealz-credit-campaign-box-upper-bar {
      position: static;
      border-radius: 0;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      #main-campagin-area .big-box .idealz-credit-campaign-box-upper-bar .single-upper-bar {
        margin-left: 5%;
        width: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 17px 0 55px 0;
        font-size: 25px;
        letter-spacing: -0.38px;
        font-family: "ABC Ginto Nord Bold";
        width: 381px; }
        #main-campagin-area .big-box .idealz-credit-campaign-box-upper-bar .single-upper-bar b {
          font-family: "ABC Ginto Nord Bold"; }
    #main-campagin-area .big-box .gauge-container .recommended-timer {
      margin-bottom: 5px; }
    #main-campagin-area .big-box .campaign-box-single {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      padding: 22px 17px 21px 17px; }
      #main-campagin-area .big-box .campaign-box-single .campaign-image {
        width: 488px;
        height: inherit;
        margin-right: 26px;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        z-index: 2; }
        #main-campagin-area .big-box .campaign-box-single .campaign-image .swiper-pagination {
          -webkit-transition: inherit;
          transition: inherit;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 18px;
          width: auto;
          padding: 0 3px;
          right: 22px;
          left: auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          height: 25px;
          bottom: 22px; }
          #main-campagin-area .big-box .campaign-box-single .campaign-image .swiper-pagination .swiper-pagination-bullet {
            background: rgba(255, 255, 255, 0.2);
            width: 12px;
            height: 12px;
            margin: 0 2px; }
            #main-campagin-area .big-box .campaign-box-single .campaign-image .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
              background: white; }
      #main-campagin-area .big-box .campaign-box-single .justLaunchedBadge {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 0;
        height: 22px;
        border-radius: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        position: absolute;
        left: 55%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        white-space: nowrap;
        padding: 0 10px;
        background: #ff6644; }
        #main-campagin-area .big-box .campaign-box-single .justLaunchedBadge .closingIn {
          color: #fff;
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif;
          font-style: italic;
          font-size: 15px; }
      #main-campagin-area .big-box .campaign-box-single .dssNewBadge {
        position: static;
        -webkit-transform: translate(0);
                transform: translate(0);
        margin-bottom: 7px;
        height: 66px;
        margin-bottom: 16px;
        padding: 0 17px;
        font-size: 17px;
        line-height: 19px;
        letter-spacing: -0.63px;
        margin-left: 0;
        margin-right: 0;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-item-align: center;
            align-self: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background: #fff500;
        border-radius: 20px; }
        #main-campagin-area .big-box .campaign-box-single .dssNewBadge .closingIn {
          line-height: 25px;
          letter-spacing: -0.38px;
          color: #3c3c3c;
          font-family: "ABC Ginto Nord Bold";
          white-space: pre;
          font-size: 25px; }
          #main-campagin-area .big-box .campaign-box-single .dssNewBadge .closingIn span {
            font-size: 25px; }
      #main-campagin-area .big-box .campaign-box-single .idealz-credit-campaign-box-upper-bar {
        overflow: hidden;
        z-index: 1;
        position: relative;
        width: 388px;
        margin-right: 66px; }
      #main-campagin-area .big-box .campaign-box-single .gauge-container {
        left: auto;
        right: 19px;
        top: 20px;
        position: static; }
      #main-campagin-area .big-box .campaign-box-single .campaign-box-copy-section {
        width: calc(100% - 488px);
        padding-bottom: 50px; }
      #main-campagin-area .big-box .campaign-box-single .campaign-information {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        height: auto;
        margin: 0 0 11px; }
        #main-campagin-area .big-box .campaign-box-single .campaign-information h4 {
          font-size: 28px;
          line-height: 28px;
          letter-spacing: -0.8px;
          text-align: left;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start; }
          #main-campagin-area .big-box .campaign-box-single .campaign-information h4 img {
            margin-left: 4px; }
          #main-campagin-area .big-box .campaign-box-single .campaign-information h4 b {
            margin: 0 3px; }
      #main-campagin-area .big-box .campaign-box-single .prize-title {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        letter-spacing: -0.8px;
        font-size: 31px;
        line-height: 33px;
        height: auto; }
        #main-campagin-area .big-box .campaign-box-single .prize-title b {
          letter-spacing: -1.45px;
          font-size: 28px;
          line-height: 33px;
          height: auto;
          font-family: "ABC Ginto Nord Bold"; }
      #main-campagin-area .big-box .campaign-box-single .additional-winner-title {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        letter-spacing: -1.45px;
        font-size: 31px;
        font-weight: 33px;
        height: auto;
        margin: 0;
        cursor: pointer; }
        #main-campagin-area .big-box .campaign-box-single .additional-winner-title b {
          letter-spacing: -1.45px;
          font-size: 31px;
          line-height: 33px;
          height: auto;
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif; }
        #main-campagin-area .big-box .campaign-box-single .additional-winner-title img {
          width: 20px;
          height: 20px;
          display: inline-block; }
      #main-campagin-area .big-box .campaign-box-single .campaign-action {
        width: 50%;
        max-width: 384px;
        margin-right: 7px;
        margin-bottom: 0; }
      #main-campagin-area .big-box .campaign-box-single .cart-super-actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      #main-campagin-area .big-box .campaign-box-single .campaign-sale-action {
        width: 384px; }
      #main-campagin-area .big-box .campaign-box-single h2 {
        letter-spacing: -2px;
        margin: 0 0 0 -12px;
        padding-right: 10px;
        font-family: "ABC Ginto Nord Bold Italic";
        background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        line-height: 95px;
        font-size: 90px;
        margin-bottom: -15px;
        display: inline-block; }
      #main-campagin-area .big-box .campaign-box-single h3 {
        text-align: left; }
      #main-campagin-area .big-box .campaign-box-single .campaign-additional-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        #main-campagin-area .big-box .campaign-box-single .campaign-additional-info .endDateBox {
          margin: 0;
          text-align: left;
          width: 50%;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1; }
      #main-campagin-area .big-box .campaign-box-single .action-icon {
        right: auto;
        left: 15px;
        bottom: 20px; }
        #main-campagin-area .big-box .campaign-box-single .action-icon li .earlybird-icon {
          font-size: 34px; }
        #main-campagin-area .big-box .campaign-box-single .action-icon li:last-child {
          margin-top: 0; }
      #main-campagin-area .big-box .campaign-box-single .new-social-icon {
        position: absolute;
        right: 19px;
        bottom: 34px;
        z-index: 10; }
        #main-campagin-area .big-box .campaign-box-single .new-social-icon > li {
          list-style: none;
          padding: 0;
          margin: 0 0 15px;
          text-align: right;
          position: relative; }
          #main-campagin-area .big-box .campaign-box-single .new-social-icon > li:last-child {
            margin-bottom: 0;
            min-height: 34px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center; }
          #main-campagin-area .big-box .campaign-box-single .new-social-icon > li a {
            background: #fff;
            border-radius: 14px;
            width: 68px;
            height: 68px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            border-radius: 50%;
            -webkit-box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
                    box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
            cursor: pointer; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li a.wishlist-icon::after {
              content: "";
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              text-align: right;
              -webkit-box-pack: end;
                  -ms-flex-pack: end;
                      justify-content: flex-end;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              cursor: pointer;
              color: #4a4a4a;
              font-size: 20px;
              background: url(../images/m/icons/wishlist-new.png) no-repeat center top;
              background-size: 100%;
              width: 24px;
              height: 21px; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li a.wishlist-icon.added::after {
              background: url(../images/m/icons/wishlist-new-active.png) no-repeat center top;
              background-size: 100%; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li a:hover {
              background: #e7e7e7;
              border-color: #e7e7e7; }
              #main-campagin-area .big-box .campaign-box-single .new-social-icon > li a:hover .toolTip {
                opacity: 1; }
          #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .add-to-wishlist-product {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end; }
          #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box > a img {
              width: 20px; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box.active .shareable-links {
              display: block; }
            #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box .shareable-links {
              display: none;
              background: #fff;
              border: 1px solid #efefef;
              border-radius: 20px;
              position: absolute;
              width: 200px;
              right: 80px;
              top: -80px;
              z-index: 1;
              background: #fff;
              -webkit-box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
                      box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2); }
              #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box .shareable-links li a {
                display: block;
                padding: 15px 20px;
                border-bottom: 1px solid #e7e7e7;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                color: #000;
                text-transform: capitalize;
                background: transparent;
                margin-bottom: 0;
                width: inherit;
                height: inherit;
                border-radius: 0;
                position: relative;
                font-size: 14px;
                text-align: left;
                -webkit-box-shadow: none;
                        box-shadow: none; }
                #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box .shareable-links li a::before {
                  margin-right: 0; }
                #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box .shareable-links li a:hover {
                  background: none;
                  border-bottom: 1px solid #e7e7e7;
                  border-left: 0;
                  border-right: 0;
                  border-top: 0; }
              #main-campagin-area .big-box .campaign-box-single .new-social-icon > li .share-trigger-box .shareable-links li:last-child a {
                border-bottom: 0; }
        #main-campagin-area .big-box .campaign-box-single .new-social-icon .toolTip {
          position: absolute;
          right: 92px;
          top: 23px;
          height: 34px;
          padding: 0 15px;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          white-space: pre;
          border-radius: 25px;
          -webkit-box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
                  box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.226667px;
          color: #000;
          background: #fff;
          opacity: 0;
          -moz-transition: all ease 0.2s;
          -o-transition: all ease 0.2s;
          -webkit-transition: all ease 0.2s;
          transition: all ease 0.2s;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
      #main-campagin-area .big-box .campaign-box-single .dsfNewCampaign {
        margin: 0;
        border-top: 0;
        border-bottom: 0;
        padding: 0;
        text-align: right;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        height: auto; }
      #main-campagin-area .big-box .campaign-box-single .timer-enabled-time {
        position: absolute;
        right: 19px;
        top: 20px;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time.allCases {
          top: 100px; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .timelimited-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time img {
          margin-right: 6px;
          width: 19px;
          height: 19px;
          -webkit-animation: blinker 1s linear infinite;
                  animation: blinker 1s linear infinite; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .closingIn {
          font-size: 16px;
          letter-spacing: -0.63px;
          color: #000;
          margin-right: 6px;
          line-height: 19px;
          white-space: pre;
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining {
          font-size: 0.9vw;
          letter-spacing: -0.28px;
          display: none;
          color: #000;
          font-size: 16px;
          letter-spacing: -0.63px;
          line-height: 19px;
          text-align: left;
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif; }
          #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining.show {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
          #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining div,
          #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining span {
            margin-right: 5px;
            text-align: right;
            padding-left: 5px;
            font-size: 16px;
            letter-spacing: -0.63px;
            font-weight: 400;
            font-family: 'Galano Grotesque Bold', sans-serif; }
            #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining div:last-child,
            #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining span:last-child {
              margin-right: 0;
              padding-left: 0;
              width: 75px; }
            #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining div span,
            #main-campagin-area .big-box .campaign-box-single .timer-enabled-time .time-remaining span span {
              font-size: 16px;
              letter-spacing: -0.63px;
              font-weight: 400;
              font-family: 'Galano Grotesque Bold', sans-serif; }
        #main-campagin-area .big-box .campaign-box-single .timer-enabled-time.red {
          -webkit-animation: bloin 1s linear infinite;
                  animation: bloin 1s linear infinite; }
          #main-campagin-area .big-box .campaign-box-single .timer-enabled-time.red .closingIn {
            color: #ff3333; }
          #main-campagin-area .big-box .campaign-box-single .timer-enabled-time.red .time-remaining {
            color: #ff3333; }
      #main-campagin-area .big-box .campaign-box-single .additional-prize-info-box {
        position: relative; }
      #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box {
        background-color: #fff;
        -webkit-box-shadow: 4px 8px 43px rgba(0, 0, 0, 0.38);
                box-shadow: 4px 8px 43px rgba(0, 0, 0, 0.38);
        border-radius: 25px;
        position: absolute;
        left: 0;
        top: calc(100% + 5px);
        width: 212px;
        max-height: 290px;
        z-index: 2;
        overflow: hidden;
        display: none; }
        #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box .scrolled {
          overflow-x: scroll;
          max-height: 290px;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */ }
          #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box .scrolled::-webkit-scrollbar {
            display: none; }
        #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box ul {
          list-style: none;
          padding: 15px 0;
          margin: 0; }
        #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box li {
          padding: 15px 15px 13px;
          border-bottom: 1px solid #efefef;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.63px;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
          #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box li span {
            color: #4200FF; }
          #main-campagin-area .big-box .campaign-box-single .additionalWinner-Box li:last-child {
            border-bottom: 0; }
      @media (max-width: 1023px) {
        #main-campagin-area .big-box .campaign-box-single {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; }
          #main-campagin-area .big-box .campaign-box-single .campaign-box-left {
            width: 69%; }
          #main-campagin-area .big-box .campaign-box-single .campaign-image {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px; }
          #main-campagin-area .big-box .campaign-box-single .campaign-box-copy-section {
            width: 100%; }
          #main-campagin-area .big-box .campaign-box-single .campaign-action.in-cart .cart-quantity {
            width: 57px; } }
    #main-campagin-area .big-box .base-product-image {
      z-index: 3; }
    #main-campagin-area .big-box .noTimeLimited {
      position: absolute;
      left: 0;
      top: -14px;
      padding: 12px 60px 12px 56px;
      min-width: 383px; }
      #main-campagin-area .big-box .noTimeLimited.dss {
        background-color: #ffd600;
        left: auto;
        right: 0;
        color: #fff; }
        #main-campagin-area .big-box .noTimeLimited.dss .closingIn {
          color: #fff; }
      #main-campagin-area .big-box .noTimeLimited.onlyText {
        color: #000; }
        #main-campagin-area .big-box .noTimeLimited.onlyText .closingIn {
          color: #000; }
    #main-campagin-area .big-box.noTimeLimitedBox .closingIn {
      font-size: 20px;
      font-style: italic;
      line-height: 19px;
      letter-spacing: -0.63px; }
    #main-campagin-area .big-box:nth-child(1) {
      z-index: 50; }
    #main-campagin-area .big-box:nth-child(2) {
      z-index: 49; }
    #main-campagin-area .big-box:nth-child(3) {
      z-index: 48; }
    #main-campagin-area .big-box:nth-child(4) {
      z-index: 47; }
    #main-campagin-area .big-box:nth-child(5) {
      z-index: 46; }
    #main-campagin-area .big-box:nth-child(6) {
      z-index: 45; }
    #main-campagin-area .big-box:nth-child(7) {
      z-index: 44; }
    #main-campagin-area .big-box:nth-child(8) {
      z-index: 43; }
    #main-campagin-area .big-box:nth-child(9) {
      z-index: 42; }
    #main-campagin-area .big-box:nth-child(10) {
      z-index: 41; }
    #main-campagin-area .big-box:nth-child(11) {
      z-index: 40; }
    #main-campagin-area .big-box:nth-child(12) {
      z-index: 39; }
    #main-campagin-area .big-box:nth-child(13) {
      z-index: 38; }
    #main-campagin-area .big-box:nth-child(14) {
      z-index: 37; }
    #main-campagin-area .big-box:nth-child(15) {
      z-index: 36; }
    #main-campagin-area .big-box:nth-child(16) {
      z-index: 35; }
    #main-campagin-area .big-box:nth-child(17) {
      z-index: 34; }
    #main-campagin-area .big-box:nth-child(18) {
      z-index: 33; }
    #main-campagin-area .big-box:nth-child(19) {
      z-index: 32; }
    #main-campagin-area .big-box:nth-child(20) {
      z-index: 31; }
    #main-campagin-area .big-box:nth-child(21) {
      z-index: 30; }
    #main-campagin-area .big-box:nth-child(22) {
      z-index: 29; }
    #main-campagin-area .big-box:nth-child(23) {
      z-index: 28; }
    #main-campagin-area .big-box:nth-child(24) {
      z-index: 27; }
    #main-campagin-area .big-box:nth-child(25) {
      z-index: 26; }
    #main-campagin-area .big-box:nth-child(26) {
      z-index: 25; }
    #main-campagin-area .big-box:nth-child(27) {
      z-index: 24; }
    #main-campagin-area .big-box:nth-child(28) {
      z-index: 23; }
    #main-campagin-area .big-box:nth-child(29) {
      z-index: 22; }
    #main-campagin-area .big-box:nth-child(30) {
      z-index: 21; }
    #main-campagin-area .big-box:nth-child(31) {
      z-index: 20; }
    #main-campagin-area .big-box:nth-child(32) {
      z-index: 19; }
    #main-campagin-area .big-box:nth-child(33) {
      z-index: 18; }
    #main-campagin-area .big-box:nth-child(34) {
      z-index: 17; }
    #main-campagin-area .big-box:nth-child(35) {
      z-index: 16; }
    #main-campagin-area .big-box:nth-child(36) {
      z-index: 15; }
    #main-campagin-area .big-box:nth-child(37) {
      z-index: 14; }
    #main-campagin-area .big-box:nth-child(38) {
      z-index: 13; }
    #main-campagin-area .big-box:nth-child(39) {
      z-index: 12; }
    #main-campagin-area .big-box:nth-child(40) {
      z-index: 11; }
    #main-campagin-area .big-box:nth-child(41) {
      z-index: 10; }
    #main-campagin-area .big-box:nth-child(42) {
      z-index: 9; }
    #main-campagin-area .big-box:nth-child(43) {
      z-index: 8; }
    #main-campagin-area .big-box:nth-child(44) {
      z-index: 7; }
    #main-campagin-area .big-box:nth-child(45) {
      z-index: 6; }
    #main-campagin-area .big-box:nth-child(46) {
      z-index: 5; }
    #main-campagin-area .big-box:nth-child(47) {
      z-index: 4; }
    #main-campagin-area .big-box:nth-child(48) {
      z-index: 3; }
    #main-campagin-area .big-box:nth-child(49) {
      z-index: 2; }
    #main-campagin-area .big-box:nth-child(50) {
      z-index: 1; }
  #main-campagin-area .tile-description {
    position: absolute;
    left: 20px;
    bottom: 20px;
    right: 16px;
    color: #5f5f5f;
    font-size: 10px;
    letter-spacing: -0.2px;
    margin: 0;
    opacity: 0.6; }
  #main-campagin-area .swiper-button-prev,
  #main-campagin-area .swiper-button-next {
    width: 58px;
    height: 48px;
    border: 1px solid #d8d8d8;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    position: absolute;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    #main-campagin-area .swiper-button-prev:hover,
    #main-campagin-area .swiper-button-next:hover {
      outline: 0; }
      #main-campagin-area .swiper-button-prev:hover::after,
      #main-campagin-area .swiper-button-next:hover::after {
        color: #000;
        outline: 0; }
    #main-campagin-area .swiper-button-prev:active,
    #main-campagin-area .swiper-button-next:active {
      outline: 0; }
    #main-campagin-area .swiper-button-prev:focus,
    #main-campagin-area .swiper-button-next:focus {
      outline: 0; }
    #main-campagin-area .swiper-button-prev::after,
    #main-campagin-area .swiper-button-next::after {
      font-size: 12px;
      color: #000;
      outline: 0; }
  #main-campagin-area .swiper-button-prev {
    right: 68px;
    top: -40px;
    left: auto; }
    #main-campagin-area .swiper-button-prev:hover {
      background: #efefef;
      border-color: #efefef; }
  #main-campagin-area .swiper-button-next {
    right: 0;
    top: -40px;
    left: auto; }
  #main-campagin-area:hover .swiper-button-next {
    background: -webkit-gradient(linear, left top, left bottom, from(#0090ff), to(#312dff));
    background: linear-gradient(180deg, #0090ff 0%, #312dff 100%);
    border-color: #312dff; }
    #main-campagin-area:hover .swiper-button-next::after {
      color: #fff; }
  #main-campagin-area .campaign-box-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 50px; }

.bottom-ticker {
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  padding: 9px 17px 9px 9px;
  height: 48px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 340px;
  width: 37%; }
  .bottom-ticker .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    .bottom-ticker .swiper-slide .icon-image {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 10%; }
    .bottom-ticker .swiper-slide img {
      margin-right: 10px;
      width: 28px;
      height: auto; }
    .bottom-ticker .swiper-slide .inner-slide {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 28px; }
  .bottom-ticker h3 {
    font-size: 10px;
    line-height: 17px;
    height: 16px;
    letter-spacing: -0.151111px;
    font-weight: normal;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .bottom-ticker h4 {
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.151111px;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .bottom-ticker h4 b {
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
  .bottom-ticker .swiper-pagination {
    width: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .bottom-ticker .swiper-pagination .swiper-pagination-bullet {
      background: #e3e3e3;
      width: 5px;
      height: 5px;
      opacity: 1;
      margin: 2px 0; }
      .bottom-ticker .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #a2a2a2; }
    .bottom-ticker .swiper-pagination.swiper-pagination-lock {
      display: none; }

.pulse {
  -webkit-animation-name: pulser;
          animation-name: pulser;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out; }

@-webkit-keyframes pulser {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  to {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05); } }

@keyframes pulser {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  to {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05); } }

@-webkit-keyframes bloin {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes bloin {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.charities-area {
  margin: 0 36px 33px; }

.charities-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .charities-container .charities-box {
    width: 49%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 40px 33px;
    border-radius: 30px;
    overflow: hidden;
    height: 374px;
    background: #fff; }
    .charities-container .charities-box.withBgImage {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      .charities-container .charities-box.withBgImage h2 {
        color: #fff; }
      .charities-container .charities-box.withBgImage a {
        color: #fff;
        border-color: #fff; }
    .charities-container .charities-box h2 {
      font-size: 31px;
      line-height: 32px;
      letter-spacing: -1.45px;
      margin: 0 0 10px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
    .charities-container .charities-box a {
      color: #000;
      border-radius: 21px;
      border: 1px solid #ccc;
      width: 122px;
      height: 28px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      letter-spacing: -0.4px;
      font-size: 12px;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .charities-container .charities-box a:hover {
        color: #4200FF;
        border-color: #4200FF; }

.charities-area {
  margin: 0 36px 33px; }

.charities-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .charities-container .charities-box {
    width: 49%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 40px 33px;
    border-radius: 30px;
    overflow: hidden;
    height: 374px;
    background: #fff; }
    .charities-container .charities-box.withBgImage {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      .charities-container .charities-box.withBgImage h2 {
        color: #fff; }
      .charities-container .charities-box.withBgImage a {
        color: #fff;
        border-color: #fff; }
    .charities-container .charities-box h2 {
      font-size: 31px;
      line-height: 32px;
      letter-spacing: -1.45px;
      margin: 0 0 10px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
    .charities-container .charities-box a {
      color: #000;
      border-radius: 21px;
      border: 1px solid #ccc;
      width: 122px;
      height: 28px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      letter-spacing: -0.4px;
      font-size: 12px;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .charities-container .charities-box a:hover {
        color: #4200FF;
        border-color: #4200FF; }

.winners-campaign-area {
  margin: 0 36px 51px; }
  .winners-campaign-area .section-title {
    color: #000;
    letter-spacing: -0.38px;
    font-size: 25px;
    line-height: 25px;
    margin: 0 0 30px;
    text-transform: capitalize;
    font-family: "ABC Ginto Nord Bold"; }
  .winners-campaign-area p {
    color: #fff;
    letter-spacing: -0.44px;
    font-size: 15px;
    line-height: 19px;
    max-width: 375px;
    margin-bottom: 28px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .winners-campaign-area .swiper-slide {
    max-width: 351px; }
    .winners-campaign-area .swiper-slide .winner-campagin-box {
      height: 373px; }

.products-campaign-area {
  margin: 0 36px 45px; }
  .products-campaign-area .winners-container {
    border-radius: 32px;
    background: #fff;
    -webkit-box-shadow: 0px 2px 16px rgba(140, 139, 139, 0.25);
            box-shadow: 0px 2px 16px rgba(140, 139, 139, 0.25);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .products-campaign-area .section-title {
    color: #000;
    letter-spacing: -0.379px;
    font-size: 25px;
    line-height: 20px;
    margin-right: 0;
    margin-bottom: 30px;
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    font-family: "ABC Ginto Nord Bold"; }
    .products-campaign-area .section-title img {
      margin-left: 8px; }
  .products-campaign-area .wallet-info {
    width: 25%;
    padding: 32px 0 33px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .products-campaign-area .wallet-info p {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      line-height: 16px;
      /* 100% */
      letter-spacing: -0.32px;
      max-width: 100%;
      margin: 0 0 12px; }
    .products-campaign-area .wallet-info h4 {
      font-size: 32px;
      font-weight: 400;
      font-family: 'Galano Grotesque ExtraBold', sans-serif;
      line-height: 46px;
      /* 143.75% */
      text-transform: uppercase;
      margin: 0 0 12px; }
    .products-campaign-area .wallet-info a {
      border-radius: 14px;
      background: linear-gradient(291deg, #C90016 0.7%, #FF001C 70.6%);
      height: 57px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%;
      max-width: 220px;
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      line-height: 19.164px;
      /* 106.465% */
      letter-spacing: -0.188px; }
  .products-campaign-area .product-slider-section {
    width: 50%;
    padding: 32px 0 33px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    overflow: visible;
    position: relative;
    z-index: 2; }
  .products-campaign-area .image-section {
    width: 25%;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    overflow: hidden;
    max-height: 280px;
    position: relative;
    z-index: 1; }
    .products-campaign-area .image-section img {
      height: 100%;
      width: auto;
      max-width: inherit; }
  .products-campaign-area p {
    color: #000;
    letter-spacing: -0.44px;
    font-size: 15px;
    line-height: 19px;
    max-width: 75%;
    margin-bottom: 28px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .products-campaign-area .swiper-slide {
    max-width: 138px; }

.soldout-campaign-area {
  margin: 0 36px 51px;
  display: none; }
  .soldout-campaign-area .section-title {
    color: #000;
    letter-spacing: -0.38px;
    font-size: 25px;
    line-height: 25px;
    margin: 0 0 30px;
    text-transform: capitalize;
    font-family: "ABC Ginto Nord Bold"; }
  .soldout-campaign-area p {
    color: #fff;
    letter-spacing: -0.44px;
    font-size: 15px;
    line-height: 19px;
    max-width: 375px;
    margin-bottom: 28px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .soldout-campaign-area .swiper-slide {
    width: 351px; }

.draw-campaign-area {
  background: #fff;
  border-radius: 35px;
  margin: 0 36px 33px;
  padding: 34px 0 0;
  display: none;
  position: relative;
  max-height: 604px;
  overflow: visible; }
  .draw-campaign-area .section-title {
    color: #000;
    letter-spacing: -1.45px;
    font-size: 31px;
    line-height: 27px;
    margin: 0 0 8px;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .draw-campaign-area p {
    color: #000;
    letter-spacing: -0.44px;
    font-size: 15px;
    line-height: 19px;
    max-width: 375px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .draw-campaign-area .swiper-container {
    padding: 0 36px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .draw-campaign-area .swiper-container::-webkit-scrollbar {
      display: none; }
  .draw-campaign-area .swiper-wrapper {
    padding: 28px 0 34px; }
  .draw-campaign-area .swiper-slide {
    display: none;
    max-width: 345px; }
    .draw-campaign-area .swiper-slide.active {
      display: block; }
  .draw-campaign-area .campaign-box-single {
    -webkit-box-shadow: 4px 8px 22px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 4px 8px 22px 0 rgba(0, 0, 0, 0.08); }
  .draw-campaign-area .swiper-button-prev,
  .draw-campaign-area .swiper-button-next {
    width: 58px;
    height: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    position: absolute;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .draw-campaign-area .swiper-button-prev:hover,
    .draw-campaign-area .swiper-button-next:hover {
      border-color: #e0e0e0;
      outline: 0; }
      .draw-campaign-area .swiper-button-prev:hover::after,
      .draw-campaign-area .swiper-button-next:hover::after {
        color: #e0e0e0;
        outline: 0; }
    .draw-campaign-area .swiper-button-prev:focus,
    .draw-campaign-area .swiper-button-next:focus {
      outline: 0; }
    .draw-campaign-area .swiper-button-prev:active,
    .draw-campaign-area .swiper-button-next:active {
      outline: 0; }
    .draw-campaign-area .swiper-button-prev::after,
    .draw-campaign-area .swiper-button-next::after {
      font-size: 12px;
      color: #e0e0e0;
      outline: 0; }
  .draw-campaign-area .swiper-button-prev {
    right: 106px;
    top: 69px;
    left: auto; }
  .draw-campaign-area .swiper-button-next {
    right: 36px;
    top: 69px;
    left: auto; }
  .draw-campaign-area .ded-title-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 36px; }
  .draw-campaign-area .ded-campaign-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 12px 13px 18px 20px;
    width: 580px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: 139px; }
    .draw-campaign-area .ded-campaign-box img {
      width: 182px; }
    .draw-campaign-area .ded-campaign-box p {
      margin: 5px 0 0;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }

.winners-campaign-area,
.products-campaign-area,
.soldout-campaign-area {
  position: relative; }
  .winners-campaign-area .swiper-button-prev,
  .winners-campaign-area .swiper-button-next,
  .products-campaign-area .swiper-button-prev,
  .products-campaign-area .swiper-button-next,
  .soldout-campaign-area .swiper-button-prev,
  .soldout-campaign-area .swiper-button-next {
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    position: absolute;
    background: #ccc;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .winners-campaign-area .swiper-button-prev:hover,
    .winners-campaign-area .swiper-button-next:hover,
    .products-campaign-area .swiper-button-prev:hover,
    .products-campaign-area .swiper-button-next:hover,
    .soldout-campaign-area .swiper-button-prev:hover,
    .soldout-campaign-area .swiper-button-next:hover {
      outline: 0;
      border: 0; }
      .winners-campaign-area .swiper-button-prev:hover::after,
      .winners-campaign-area .swiper-button-next:hover::after,
      .products-campaign-area .swiper-button-prev:hover::after,
      .products-campaign-area .swiper-button-next:hover::after,
      .soldout-campaign-area .swiper-button-prev:hover::after,
      .soldout-campaign-area .swiper-button-next:hover::after {
        color: #000;
        outline: 0; }
    .winners-campaign-area .swiper-button-prev:active,
    .winners-campaign-area .swiper-button-next:active,
    .products-campaign-area .swiper-button-prev:active,
    .products-campaign-area .swiper-button-next:active,
    .soldout-campaign-area .swiper-button-prev:active,
    .soldout-campaign-area .swiper-button-next:active {
      outline: 0; }
    .winners-campaign-area .swiper-button-prev::after,
    .winners-campaign-area .swiper-button-next::after,
    .products-campaign-area .swiper-button-prev::after,
    .products-campaign-area .swiper-button-next::after,
    .soldout-campaign-area .swiper-button-prev::after,
    .soldout-campaign-area .swiper-button-next::after {
      font-size: 14px;
      color: #000;
      outline: 0; }
    .winners-campaign-area .swiper-button-prev:focus,
    .winners-campaign-area .swiper-button-next:focus,
    .products-campaign-area .swiper-button-prev:focus,
    .products-campaign-area .swiper-button-next:focus,
    .soldout-campaign-area .swiper-button-prev:focus,
    .soldout-campaign-area .swiper-button-next:focus {
      outline: 0; }
  .winners-campaign-area .swiper-button-prev,
  .products-campaign-area .swiper-button-prev,
  .soldout-campaign-area .swiper-button-prev {
    right: 86px;
    top: 18px;
    left: auto; }
  .winners-campaign-area .swiper-button-next,
  .products-campaign-area .swiper-button-next,
  .soldout-campaign-area .swiper-button-next {
    right: 36px;
    top: 18px;
    left: auto; }

.idealzbasics-product-box {
  max-width: 138px; }
  .idealzbasics-product-box .idealzbasics-product-image {
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 11px;
    border: 1px solid rgba(154, 154, 154, 0.15); }
    .idealzbasics-product-box .idealzbasics-product-image img {
      width: 100%; }
  .idealzbasics-product-box h6 {
    color: #919191;
    letter-spacing: -0.348px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif;
    margin: 0;
    text-align: center; }
  .idealzbasics-product-box h5 {
    color: #E7011A;
    letter-spacing: -0.348px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif;
    margin: 0;
    text-align: center; }

.winner-campagin-box,
.soldout-campagin-box {
  background: #fff;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .winner-campagin-box .campaign-image,
  .soldout-campagin-box .campaign-image {
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    height: auto;
    max-height: inherit;
    overflow: hidden;
    margin: 0 0 20px;
    border-radius: 25px; }
  .winner-campagin-box .winnerImage,
  .soldout-campagin-box .winnerImage {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 86px;
    height: 86px;
    border: 5px solid #7000ff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden; }
    .winner-campagin-box .winnerImage img,
    .soldout-campagin-box .winnerImage img {
      border: 3px solid #fff;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
  .winner-campagin-box h4,
  .soldout-campagin-box h4 {
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif;
    color: #ff3e3e;
    text-align: center;
    font-size: 23px;
    line-height: 27px;
    letter-spacing: -0.92px;
    margin: 0;
    font-style: italic;
    padding: 0 15px; }
  .winner-campagin-box h5,
  .soldout-campagin-box h5 {
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif;
    letter-spacing: -0.63px;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    margin: 10px 0;
    padding: 0; }
    .winner-campagin-box h5 b,
    .soldout-campagin-box h5 b {
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
  .winner-campagin-box h6,
  .soldout-campagin-box h6 {
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.15px;
    text-align: center;
    margin: 5px 0 8px;
    padding: 0 15px; }
    .winner-campagin-box h6 > span,
    .soldout-campagin-box h6 > span {
      text-align: center;
      display: block; }
    .winner-campagin-box h6 b,
    .soldout-campagin-box h6 b {
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif; }
  .winner-campagin-box .winner-link-video,
  .soldout-campagin-box .winner-link-video {
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.43px;
    text-align: center;
    color: #000;
    width: 150px;
    height: 29px;
    border-radius: 14px;
    border: 1px solid #c1c1c1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    padding: 0 15px; }
    .winner-campagin-box .winner-link-video span,
    .soldout-campagin-box .winner-link-video span {
      background: rgba(120, 120, 120, 0.470252);
      width: 23px;
      height: 23px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-radius: 50%;
      margin-right: 5px;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s; }
      .winner-campagin-box .winner-link-video span img,
      .soldout-campagin-box .winner-link-video span img {
        width: 8.8px; }
  .winner-campagin-box .winnerInfoBox,
  .soldout-campagin-box .winnerInfoBox {
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 15px; }
    .winner-campagin-box .winnerInfoBox h5,
    .soldout-campagin-box .winnerInfoBox h5 {
      font-size: 18px;
      letter-spacing: -0.08px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .winner-campagin-box .winnerInfoBox h5 b,
      .soldout-campagin-box .winnerInfoBox h5 b {
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
  .winner-campagin-box .additionalWinner-Box,
  .soldout-campagin-box .additionalWinner-Box {
    padding: 18px 0 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 89%;
    background: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    height: 0;
    opacity: 0;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    z-index: -1; }
    .winner-campagin-box .additionalWinner-Box.active,
    .soldout-campagin-box .additionalWinner-Box.active {
      opacity: 1;
      height: auto;
      z-index: 1; }
    .winner-campagin-box .additionalWinner-Box img,
    .soldout-campagin-box .additionalWinner-Box img {
      left: 0;
      right: 0;
      position: absolute; }
      .winner-campagin-box .additionalWinner-Box img.top,
      .soldout-campagin-box .additionalWinner-Box img.top {
        top: 0; }
      .winner-campagin-box .additionalWinner-Box img.bottom,
      .soldout-campagin-box .additionalWinner-Box img.bottom {
        bottom: 55px; }
    .winner-campagin-box .additionalWinner-Box ul,
    .soldout-campagin-box .additionalWinner-Box ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .winner-campagin-box .additionalWinner-Box ul li,
      .soldout-campagin-box .additionalWinner-Box ul li {
        border-bottom: 1px solid #efefef;
        padding-bottom: 12px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 15px; }
        .winner-campagin-box .additionalWinner-Box ul li:last-child,
        .soldout-campagin-box .additionalWinner-Box ul li:last-child {
          border-bottom: 0;
          padding-bottom: 0; }
        .winner-campagin-box .additionalWinner-Box ul li .winner-name,
        .soldout-campagin-box .additionalWinner-Box ul li .winner-name {
          letter-spacing: -0.63px;
          font-size: 14px;
          line-height: 15px;
          text-align: center;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
          .winner-campagin-box .additionalWinner-Box ul li .winner-name span,
          .soldout-campagin-box .additionalWinner-Box ul li .winner-name span {
            color: #4200FF; }
        .winner-campagin-box .additionalWinner-Box ul li .on-winning,
        .soldout-campagin-box .additionalWinner-Box ul li .on-winning {
          letter-spacing: -0.63px;
          font-size: 14px;
          line-height: 15px;
          text-align: center; }
          .winner-campagin-box .additionalWinner-Box ul li .on-winning b,
          .soldout-campagin-box .additionalWinner-Box ul li .on-winning b {
            font-weight: 400;
            font-family: 'Galano Grotesque Medium', sans-serif; }
        .winner-campagin-box .additionalWinner-Box ul li .winner-ticket,
        .soldout-campagin-box .additionalWinner-Box ul li .winner-ticket {
          font-size: 8px;
          line-height: 11px;
          letter-spacing: -0.151111px;
          text-align: center; }
    .winner-campagin-box .additionalWinner-Box .winner-link-video,
    .soldout-campagin-box .additionalWinner-Box .winner-link-video {
      width: auto;
      padding: 0 13px; }
    .winner-campagin-box .additionalWinner-Box .close-winner-box,
    .soldout-campagin-box .additionalWinner-Box .close-winner-box {
      background: #fff;
      padding: 12px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      overflow: hidden;
      border: 1px solid #efefef; }
    .winner-campagin-box .additionalWinner-Box .scrolled,
    .soldout-campagin-box .additionalWinner-Box .scrolled {
      overflow-x: scroll;
      max-height: 263px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .winner-campagin-box .additionalWinner-Box .scrolled::-webkit-scrollbar,
      .soldout-campagin-box .additionalWinner-Box .scrolled::-webkit-scrollbar {
        display: none; }

.winner-campagin-box {
  padding: 0;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .winner-campagin-box h4 {
    color: #EF162E;
    font-size: 22px;
    letter-spacing: -0.4px;
    text-align: center;
    text-transform: uppercase;
    font-family: "ABC Ginto Nord Bold"; }

.winners-campaign-area {
  max-height: 564px; }
  .winners-campaign-area .swiper-container {
    overflow-x: visible;
    overflow-y: hidden;
    z-index: 2;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .winners-campaign-area .swiper-container::-webkit-scrollbar {
      display: none; }
  .winners-campaign-area .additionalWinner-Box {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: rgba(22, 22, 22, 0.33);
    z-index: 52; }
    .winners-campaign-area .additionalWinner-Box .box {
      width: 345px;
      height: 508px;
      background: #fff;
      border-radius: 25px; }
    .winners-campaign-area .additionalWinner-Box .boxHeader {
      height: 111px;
      -webkit-box-shadow: 0 8px 4px rgba(0, 0, 0, 0.05);
              box-shadow: 0 8px 4px rgba(0, 0, 0, 0.05);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .winners-campaign-area .additionalWinner-Box .boxHeader h4 {
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.63px;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        font-weight: normal;
        text-align: center; }
      .winners-campaign-area .additionalWinner-Box .boxHeader h6 {
        letter-spacing: -0.151111px;
        text-align: center;
        font-size: 12px;
        line-height: 11px;
        font-weight: normal; }
    .winners-campaign-area .additionalWinner-Box li {
      border-bottom: 1px solid #efefef;
      padding-bottom: 12px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px; }
      .winners-campaign-area .additionalWinner-Box li:last-child {
        border-bottom: 0; }
    .winners-campaign-area .additionalWinner-Box .winner-name {
      letter-spacing: -0.63px;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .winners-campaign-area .additionalWinner-Box .winner-name span {
        color: #4200FF; }
    .winners-campaign-area .additionalWinner-Box .on-winning {
      letter-spacing: -0.63px;
      font-size: 14px;
      line-height: 15px;
      text-align: center; }
      .winners-campaign-area .additionalWinner-Box .on-winning b {
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .winners-campaign-area .additionalWinner-Box .winner-ticket {
      font-size: 8px;
      line-height: 11px;
      letter-spacing: -0.151111px;
      text-align: center; }
    .winners-campaign-area .additionalWinner-Box .scrolled {
      overflow-x: scroll;
      max-height: 397px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .winners-campaign-area .additionalWinner-Box .scrolled::-webkit-scrollbar {
        display: none; }

.additionalWinner-Box-new {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(22, 22, 22, 0.33);
  z-index: 52; }
  .additionalWinner-Box-new .box {
    width: 345px;
    background: #fff;
    border-radius: 25px; }
  .additionalWinner-Box-new .boxHeader {
    min-height: 111px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    .additionalWinner-Box-new .boxHeader h4 {
      font-size: 18px;
      line-height: 19px;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif;
      font-weight: normal;
      text-align: center; }
    .additionalWinner-Box-new .boxHeader h6 {
      letter-spacing: -0.151111px;
      text-align: center;
      font-size: 12px;
      line-height: 11px;
      font-weight: normal; }
    .additionalWinner-Box-new .boxHeader .winner-closeIcon {
      position: absolute;
      right: 16px;
      width: 9px;
      top: 14px;
      cursor: pointer; }
  .additionalWinner-Box-new li {
    border-bottom: 1px solid #efefef;
    padding-bottom: 12px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px; }
    .additionalWinner-Box-new li:last-child {
      border-bottom: 0; }
  .additionalWinner-Box-new ul {
    padding-bottom: 20px; }
  .additionalWinner-Box-new .winner-name {
    letter-spacing: -0.63px;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .additionalWinner-Box-new .winner-name span {
      color: #4200FF; }
  .additionalWinner-Box-new .on-winning {
    letter-spacing: -0.63px;
    font-size: 14px;
    line-height: 15px;
    text-align: center; }
    .additionalWinner-Box-new .on-winning b {
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
  .additionalWinner-Box-new .winner-ticket {
    font-size: 8px;
    line-height: 11px;
    letter-spacing: -0.151111px;
    text-align: center; }
  .additionalWinner-Box-new .scrolled {
    overflow-x: scroll;
    max-height: 80vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .additionalWinner-Box-new .scrolled::-webkit-scrollbar {
      display: none; }

.products-campaign-area {
  max-height: 564px;
  position: relative; }
  .products-campaign-area .swiper-container {
    overflow-x: visible;
    overflow-y: hidden;
    z-index: 2;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .products-campaign-area .swiper-container::-webkit-scrollbar {
      display: none; }
  .products-campaign-area .additionalWinner-Box {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: rgba(22, 22, 22, 0.33);
    z-index: 52; }
    .products-campaign-area .additionalWinner-Box .box {
      width: 345px;
      height: 508px;
      background: #fff;
      border-radius: 25px; }
    .products-campaign-area .additionalWinner-Box .boxHeader {
      height: 111px;
      -webkit-box-shadow: 0 8px 4px rgba(0, 0, 0, 0.05);
              box-shadow: 0 8px 4px rgba(0, 0, 0, 0.05);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .products-campaign-area .additionalWinner-Box .boxHeader h4 {
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.63px;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        font-weight: normal;
        text-align: center; }
      .products-campaign-area .additionalWinner-Box .boxHeader h6 {
        letter-spacing: -0.151111px;
        text-align: center;
        font-size: 12px;
        line-height: 11px;
        font-weight: normal; }
    .products-campaign-area .additionalWinner-Box li {
      border-bottom: 1px solid #efefef;
      padding-bottom: 12px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px; }
      .products-campaign-area .additionalWinner-Box li:last-child {
        border-bottom: 0; }
    .products-campaign-area .additionalWinner-Box .winner-name {
      letter-spacing: -0.63px;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .products-campaign-area .additionalWinner-Box .winner-name span {
        color: #4200FF; }
    .products-campaign-area .additionalWinner-Box .on-winning {
      letter-spacing: -0.63px;
      font-size: 14px;
      line-height: 15px;
      text-align: center; }
      .products-campaign-area .additionalWinner-Box .on-winning b {
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .products-campaign-area .additionalWinner-Box .winner-ticket {
      font-size: 8px;
      line-height: 11px;
      letter-spacing: -0.151111px;
      text-align: center; }
    .products-campaign-area .additionalWinner-Box .scrolled {
      overflow-x: scroll;
      max-height: 397px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .products-campaign-area .additionalWinner-Box .scrolled::-webkit-scrollbar {
        display: none; }
  .products-campaign-area .swiper-button-prev,
  .products-campaign-area .swiper-button-next {
    width: 58px;
    height: 48px;
    border: 1px solid #000;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    position: absolute;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s; }
    .products-campaign-area .swiper-button-prev:hover,
    .products-campaign-area .swiper-button-next:hover {
      border-color: #000;
      outline: 0; }
      .products-campaign-area .swiper-button-prev:hover::after,
      .products-campaign-area .swiper-button-next:hover::after {
        color: #000;
        outline: 0; }
    .products-campaign-area .swiper-button-prev:active,
    .products-campaign-area .swiper-button-next:active {
      outline: 0; }
    .products-campaign-area .swiper-button-prev::after,
    .products-campaign-area .swiper-button-next::after {
      font-size: 12px;
      color: #000;
      outline: 0; }
    .products-campaign-area .swiper-button-prev:focus,
    .products-campaign-area .swiper-button-next:focus {
      outline: 0; }
  .products-campaign-area .swiper-button-prev {
    right: 106px;
    top: 54px;
    left: auto; }
  .products-campaign-area .swiper-button-next {
    right: 36px;
    top: 54px;
    left: auto; }

.campaign-detail .entry-ticket {
  letter-spacing: -0.3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  font-family: 'Galano Grotesque', sans-serif; }
  .campaign-detail .entry-ticket p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 10px; }
  .campaign-detail .entry-ticket i {
    color: #f6c161;
    font-size: 24px;
    margin-right: 0;
    height: 33px; }

.campaign-detail .contentCampaign {
  display: none;
  min-height: 300px; }
  .campaign-detail .contentCampaign#content1 {
    display: block; }

.campaign-detail .action-icon {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .campaign-detail .action-icon li {
    font-size: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .campaign-detail .action-icon li .earlybird-icon {
      font-size: 25px;
      color: #f6c161;
      height: 26px; }
  .campaign-detail .action-icon li .earlybird-icon {
    margin-left: 2px; }

.campaign-detail .main-product-banner {
  margin-top: 30px;
  margin-bottom: 36px; }
  .campaign-detail .main-product-banner .swiper-slide {
    height: 149px;
    width: 149px;
    margin-right: 15px;
    border-radius: 20px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    .campaign-detail .main-product-banner .swiper-slide:last-child {
      margin-right: 0; }
    .campaign-detail .main-product-banner .swiper-slide img {
      height: 100%;
      width: auto;
      max-width: initial; }
    .campaign-detail .main-product-banner .swiper-slide .seeeMore {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.45);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #fff;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.651852px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }

.campaign-detail .share-trigger-box {
  position: relative; }
  .campaign-detail .share-trigger-box.active .shareable-links {
    display: block; }
  .campaign-detail .share-trigger-box .shareable-links {
    display: none;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    width: 220px;
    right: 50%;
    bottom: 110%;
    z-index: 2;
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }
    .campaign-detail .share-trigger-box .shareable-links li a {
      display: block;
      padding: 15px 20px;
      border-bottom: 1px solid #e7e7e7;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      color: #000;
      text-transform: capitalize;
      background: transparent;
      margin-bottom: 0;
      width: inherit;
      height: inherit;
      border-radius: 0;
      position: relative;
      font-size: 14px; }
      .campaign-detail .share-trigger-box .shareable-links li a::before {
        margin-right: 0; }
      .campaign-detail .share-trigger-box .shareable-links li a:hover {
        background: none;
        border-bottom: 1px solid #e7e7e7;
        border-left: 0;
        border-right: 0;
        border-top: 0; }
    .campaign-detail .share-trigger-box .shareable-links li:last-child a {
      border-bottom: 0; }

.error-message {
  text-align: center;
  font-weight: normal; }

.error-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  .error-box pre {
    white-space: initial; }

#add-to-cart-box {
  background: #ffffff;
  padding: 14px 25px 13px;
  position: fixed;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  -webkit-box-shadow: 0 -1px 29px -4px rgba(0, 0, 0, 0.15);
          box-shadow: 0 -1px 29px -4px rgba(0, 0, 0, 0.15);
  height: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  font-family: 'Galano Grotesque', sans-serif; }
  #add-to-cart-box .campaign-action {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 1;
        flex-basis: 1;
    width: 14%; }
    #add-to-cart-box .campaign-action.in-cart .add-to-cart-btn {
      margin-right: 7px; }
    #add-to-cart-box .campaign-action.in-cart .cart-quantity,
    #add-to-cart-box .campaign-action.in-cart .minus-from-cart-btn {
      height: 59px;
      border-radius: 17px; }
    #add-to-cart-box .campaign-action.in-cart .cart-quantity {
      font-size: 17px;
      width: 64px;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    #add-to-cart-box .campaign-action.in-cart.detail-page-cart .add-to-cart-btn {
      height: 59px;
      width: calc(100% - 150px); }
  #add-to-cart-box p {
    font-size: 18px;
    letter-spacing: -0.334px;
    color: #000;
    margin: 0;
    text-align: left;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    line-height: 20px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    #add-to-cart-box p b {
      color: #4200FF;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
    #add-to-cart-box p .inclusive {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.185714px;
      display: block;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }

.inner-page-wrapper {
  padding: 15px 0; }
  .inner-page-wrapper h1 {
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
  .inner-page-wrapper img {
    width: 100%; }
  .inner-page-wrapper p {
    font-size: 12px;
    line-height: 1.3;
    margin: 0 0 30px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .inner-page-wrapper p b,
    .inner-page-wrapper p strong {
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
  .inner-page-wrapper iframe {
    height: 200px; }
  .inner-page-wrapper ol {
    padding: 10px 0 0 10px;
    margin-top: 20px; }
    .inner-page-wrapper ol.simple-ol {
      padding: 0;
      list-style: none;
      counter-reset: list;
      margin-bottom: 0; }
      .inner-page-wrapper ol.simple-ol li {
        padding-left: 30px;
        margin-bottom: 20px;
        position: relative;
        font-size: 120px;
        line-height: 1.667;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .inner-page-wrapper ol.simple-ol li b,
        .inner-page-wrapper ol.simple-ol li strong {
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif; }
        .inner-page-wrapper ol.simple-ol li::before {
          counter-increment: list;
          content: counter(list, lower-alpha) ".";
          position: absolute;
          left: 0; }
  .inner-page-wrapper .about-us p {
    text-align: center; }
  .inner-page-wrapper h3 {
    font-size: 18px;
    margin: 35px 0;
    text-align: center;
    line-height: 1;
    font-weight: normal;
    color: #000;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
  .inner-page-wrapper ul.faqs {
    list-style: none;
    margin: 0 auto 30px;
    padding: 0; }
    .inner-page-wrapper ul.faqs li {
      text-align: center; }
    .inner-page-wrapper ul.faqs p.question {
      font-size: 12px;
      margin: 0;
      display: inline-block;
      width: 360px;
      max-width: calc(100% - 40px);
      text-align: center;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .inner-page-wrapper ul.faqs p a {
      color: #ff6262; }
    .inner-page-wrapper ul.faqs .answer {
      margin: 0; }
      .inner-page-wrapper ul.faqs .answer p {
        display: inline-block;
        width: 360px;
        max-width: calc(100% - 40px);
        text-align: center;
        font-size: 12px;
        line-height: 1.61; }
  .inner-page-wrapper .user-agreement ol,
  .inner-page-wrapper .privacy-policy ol {
    counter-reset: item;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
    list-style: none; }
    .inner-page-wrapper .user-agreement ol li,
    .inner-page-wrapper .privacy-policy ol li {
      margin: 0 0 20px;
      font-size: 15px;
      display: block; }
      .inner-page-wrapper .user-agreement ol li::before,
      .inner-page-wrapper .privacy-policy ol li::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        direction: ltr; }
      .inner-page-wrapper .user-agreement ol li ol,
      .inner-page-wrapper .privacy-policy ol li ol {
        margin-top: 0; }
        .inner-page-wrapper .user-agreement ol li ol li,
        .inner-page-wrapper .privacy-policy ol li ol li {
          padding-left: 30px;
          margin-bottom: 20px;
          margin-top: 0;
          font-size: 12px;
          line-height: 1.667;
          position: relative; }
          .inner-page-wrapper .user-agreement ol li ol li::before,
          .inner-page-wrapper .privacy-policy ol li ol li::before {
            position: absolute;
            left: 0; }
          .inner-page-wrapper .user-agreement ol li ol li > ol > li,
          .inner-page-wrapper .privacy-policy ol li ol li > ol > li {
            padding-left: 45px; }

.bottom-ticker {
  border: inherit;
  border-radius: 0;
  padding: 0;
  height: 73px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  width: 50%;
  background: transparent; }
  .bottom-ticker .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    .bottom-ticker .swiper-slide .icon-image {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 10%; }
    .bottom-ticker .swiper-slide img {
      margin-right: 10px;
      width: 28px;
      height: auto; }
    .bottom-ticker .swiper-slide .inner-slide {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 28px; }
  .bottom-ticker h3 {
    font-size: 10px;
    line-height: 17px;
    height: 16px;
    letter-spacing: -0.151111px;
    font-weight: normal;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .bottom-ticker h4 {
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.151111px;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    color: #a6a6a6;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .bottom-ticker h4 b {
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
  .bottom-ticker .swiper-pagination {
    width: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .bottom-ticker .swiper-pagination .swiper-pagination-bullet {
      background: #e3e3e3;
      width: 5px;
      height: 5px;
      opacity: 1;
      margin: 2px 0; }
      .bottom-ticker .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #a2a2a2; }
    .bottom-ticker .swiper-pagination.swiper-pagination-lock {
      display: none; }

.limited-campaign-badge,
.noTimeLimited {
  height: 77px;
  padding: 0 12px;
  background-color: #ff6262;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-bottom: -45px;
  padding-top: 12px; }
  .limited-campaign-badge .timelimited-title,
  .noTimeLimited .timelimited-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .limited-campaign-badge img,
  .noTimeLimited img {
    margin-right: 6px;
    width: 19px;
    height: 19px;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite; }
  .limited-campaign-badge .closingIn,
  .noTimeLimited .closingIn {
    letter-spacing: -0.62px;
    color: #fff;
    margin-right: 6px;
    line-height: 22px;
    white-space: pre;
    font-style: italic;
    font-size: 1vw;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
    .limited-campaign-badge .closingIn.finished,
    .noTimeLimited .closingIn.finished {
      font-size: 14px;
      letter-spacing: -0.43px;
      font-style: normal;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    @media (max-width: 1360px) {
      .limited-campaign-badge .closingIn,
      .noTimeLimited .closingIn {
        font-size: 14px; } }
    @media (min-width: 2000px) {
      .limited-campaign-badge .closingIn,
      .noTimeLimited .closingIn {
        font-style: italic;
        font-size: 16px; } }
  .limited-campaign-badge .time-remaining,
  .noTimeLimited .time-remaining {
    font-size: 0.9vw;
    letter-spacing: -0.28px;
    color: #fff;
    display: none;
    line-height: 15px;
    margin-top: 5px;
    white-space: pre;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
    .limited-campaign-badge .time-remaining.show,
    .noTimeLimited .time-remaining.show {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .limited-campaign-badge .time-remaining div,
    .limited-campaign-badge .time-remaining span,
    .noTimeLimited .time-remaining div,
    .noTimeLimited .time-remaining span {
      margin-right: 5px;
      text-align: right;
      padding-left: 5px; }
      .limited-campaign-badge .time-remaining div:last-child,
      .limited-campaign-badge .time-remaining span:last-child,
      .noTimeLimited .time-remaining div:last-child,
      .noTimeLimited .time-remaining span:last-child {
        margin-right: 0;
        padding-left: 0;
        width: 55px; }
      .limited-campaign-badge .time-remaining div span,
      .limited-campaign-badge .time-remaining span span,
      .noTimeLimited .time-remaining div span,
      .noTimeLimited .time-remaining span span {
        font-size: 0.9vw;
        letter-spacing: -0.28px;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
    @media (max-width: 1360px) {
      .limited-campaign-badge .time-remaining,
      .noTimeLimited .time-remaining {
        font-size: 1.3vw; }
        .limited-campaign-badge .time-remaining div,
        .limited-campaign-badge .time-remaining span,
        .noTimeLimited .time-remaining div,
        .noTimeLimited .time-remaining span {
          font-size: 1.3vw; }
          .limited-campaign-badge .time-remaining div span,
          .limited-campaign-badge .time-remaining span span,
          .noTimeLimited .time-remaining div span,
          .noTimeLimited .time-remaining span span {
            font-size: 1.3vw; } }
    @media (max-width: 1023px) {
      .limited-campaign-badge .time-remaining,
      .noTimeLimited .time-remaining {
        font-size: 1.7vw; }
        .limited-campaign-badge .time-remaining div,
        .limited-campaign-badge .time-remaining span,
        .noTimeLimited .time-remaining div,
        .noTimeLimited .time-remaining span {
          font-size: 1.8vw; }
          .limited-campaign-badge .time-remaining div span,
          .limited-campaign-badge .time-remaining span span,
          .noTimeLimited .time-remaining div span,
          .noTimeLimited .time-remaining span span {
            font-size: 1.8vw; } }
    @media (min-width: 2000px) {
      .limited-campaign-badge .time-remaining,
      .noTimeLimited .time-remaining {
        font-size: 16px; }
        .limited-campaign-badge .time-remaining div,
        .limited-campaign-badge .time-remaining span,
        .noTimeLimited .time-remaining div,
        .noTimeLimited .time-remaining span {
          font-size: 16px; }
          .limited-campaign-badge .time-remaining div span,
          .limited-campaign-badge .time-remaining span span,
          .noTimeLimited .time-remaining div span,
          .noTimeLimited .time-remaining span span {
            font-size: 16px; } }
  .limited-campaign-badge.soldout img, .limited-campaign-badge.sold-out img,
  .noTimeLimited.soldout img,
  .noTimeLimited.sold-out img {
    margin-top: -4px;
    -webkit-animation: none;
            animation: none; }
  .limited-campaign-badge.onlyText,
  .noTimeLimited.onlyText {
    background-color: #ffd600; }
    .limited-campaign-badge.onlyText .closingIn,
    .noTimeLimited.onlyText .closingIn {
      color: #000; }

.noTimeLimited {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.coming-soon-campaign .limited-campaign-badge,
.coming-soon-campaign .noTimeLimited {
  background: #61c652; }

.coming-soon-campaign .global-btn {
  background: #e3e3e3;
  pointer-events: none;
  color: #fff; }

.coming-soon-campaign .js-add-to-wish-list {
  pointer-events: none; }

.coming-soon-campaign .share-trigger {
  pointer-events: none; }

.coming-soon-campaign .campaign-image a {
  pointer-events: none; }

.sold-out .global-btn {
  background: #e3e3e3;
  pointer-events: none;
  color: #fff; }

.sold-out .quickview {
  pointer-events: none; }

.sold-out .js-add-to-wish-list {
  pointer-events: none; }

.sold-out .share-trigger {
  pointer-events: none; }

.sold-out .campaign-image a {
  pointer-events: none; }

.main-site-banner {
  margin: 0 36px 26px;
  border-radius: 25px;
  overflow: hidden; }
  .main-site-banner .slick-slide {
    position: relative; }
    .main-site-banner .slick-slide img {
      width: 100%;
      height: auto; }
  .main-site-banner .banner-carousel-cta {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .main-site-banner .banner-carousel-cta.center-aligned {
      text-align: center; }
    .main-site-banner .banner-carousel-cta.right-aligned {
      text-align: right; }
    .main-site-banner .banner-carousel-cta .banner-carousel-cta-text {
      color: #ffffff;
      font-weight: normal;
      margin: 0;
      white-space: pre-line;
      line-height: 44px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .main-site-banner .banner-carousel-cta a {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: -0.13px;
      color: #ffffff;
      text-decoration: none;
      line-height: 44px;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
  .main-site-banner .carousel-copy-meta {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 60.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 43%; }
    .main-site-banner .carousel-copy-meta .download-text {
      max-width: 762px;
      width: 100%; }
    .main-site-banner .carousel-copy-meta .appstore,
    .main-site-banner .carousel-copy-meta .playstore {
      display: inline-block;
      margin-top: 2%;
      margin-bottom: 3%;
      width: 21%; }
    .main-site-banner .carousel-copy-meta .appstore {
      margin-right: 2%;
      margin-left: -14%; }
    .main-site-banner .carousel-copy-meta .online {
      width: 33%;
      margin-left: 27.5%; }
  .main-site-banner .slick-arrow {
    background-color: rgba(255, 255, 255, 0.1); }
    .main-site-banner .slick-arrow::before {
      color: rgba(255, 255, 255, 0.26); }
    .main-site-banner .slick-arrow:hover {
      background-color: rgba(255, 255, 255, 0.19); }
      .main-site-banner .slick-arrow:hover::before {
        color: white; }

@media (max-width: 1023px) {
  .main-site-banner .banner-carousel-cta .banner-carousel-cta-text {
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.7px; } }

@media (max-width: 1360px) {
  .main-site-banner .banner-carousel-cta .banner-carousel-cta-text {
    line-height: 0.99;
    letter-spacing: -2.06px;
    font-size: 42px; } }

.offers-home-section {
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  height: 100%; }
  .offers-home-section .swiper-slide .offers-section {
    padding: 10px 30px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 30px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .offers-home-section .swiper-slide .offers-section .offers-copy {
      width: 100%;
      opacity: 0;
      -webkit-transition: all ease 0.6s;
      transition: all ease 0.6s;
      margin-bottom: 10px; }
      .offers-home-section .swiper-slide .offers-section .offers-copy h5 {
        color: #fff;
        letter-spacing: -0.5px;
        font-size: 24px;
        line-height: 24px;
        margin: 0;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
        .offers-home-section .swiper-slide .offers-section .offers-copy h5 b {
          font-weight: normal;
          font-weight: 400;
          font-family: 'Galano Grotesque Bold', sans-serif; }
      .offers-home-section .swiper-slide .offers-section .offers-copy p {
        color: #fff;
        font-size: 14px; }
        .offers-home-section .swiper-slide .offers-section .offers-copy p.headingtitle {
          margin-bottom: 10px; }
    .offers-home-section .swiper-slide .offers-section .cta-offers {
      background: #fff;
      border-radius: 19px;
      width: 140px;
      height: 35px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.383801px;
      color: #000;
      opacity: 0;
      -webkit-transition: all ease 0.6s;
      transition: all ease 0.6s;
      margin-top: 5px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
    .offers-home-section .swiper-slide .offers-section.green {
      background: linear-gradient(326.97deg, #4200FF -74.45%, #05FF00 122.34%); }
    .offers-home-section .swiper-slide .offers-section.purple {
      background: linear-gradient(74.19deg, #4200FF 10.28%, #FF008A 121.72%); }
    .offers-home-section .swiper-slide .offers-section.orange {
      background: linear-gradient(298.61deg, #FF0000 -4.14%, #FF9900 93.23%); }
  .offers-home-section .swiper-pagination {
    bottom: 18px;
    left: 28px;
    -webkit-transform: none;
            transform: none;
    width: auto; }
    .offers-home-section .swiper-pagination .swiper-pagination-bullet {
      background: rgba(0, 0, 0, 0.34);
      width: 5px;
      height: 5px; }
      .offers-home-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff; }
  .offers-home-section .swiper-slide-active .offers-section .offers-copy,
  .offers-home-section .swiper-slide-active .offers-section .cta-offers {
    opacity: 1; }

.offer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

.active-offer {
  margin-bottom: 21px;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  height: 267px;
  width: 49%; }
  .active-offer.green {
    background: linear-gradient(346.49deg, #296E92 10.15%, #10D62A 74.4%); }
  .active-offer.purple {
    background: linear-gradient(73.24deg, #4301FF 11.52%, #D201A7 121.42%); }
  .active-offer.orange {
    background: linear-gradient(341.05deg, #FF3301 38.15%, #FF8701 83.37%); }
  .active-offer img {
    width: 100%;
    display: block; }
  .active-offer .offer-content {
    padding: 19px 23px 27px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .active-offer .headingtitle {
    letter-spacing: -0.5px;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .active-offer .mainheading {
    letter-spacing: -1.2px;
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    margin: 0 0 8px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .active-offer .subtitle {
    letter-spacing: -0.5px;
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 10px;
    color: #fff; }
  .active-offer .offer-btn {
    background-color: #fff;
    width: 163px;
    height: 57px;
    border-radius: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #000000;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.249023px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }

@media (max-width: 1023px) {
  .offers-home-section .swiper-slide .offers-section {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 20px 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 93px; }
    .offers-home-section .swiper-slide .offers-section .offers-copy h5 {
      font-size: 18px;
      line-height: 18px; } }

.idealz-credit-campaign-box-upper-bar {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: -40px;
  right: 0;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0; }
  .idealz-credit-campaign-box-upper-bar .single-upper-bar {
    background: #FF001C;
    padding: 13px 0 52px;
    width: 100%;
    color: #fff;
    font-size: 21px;
    line-height: 21px;
    letter-spacing: -0.5px;
    height: 100%;
    text-align: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Black', sans-serif; }
    .idealz-credit-campaign-box-upper-bar .single-upper-bar b {
      font-weight: 400;
      font-family: 'Galano Grotesque Black', sans-serif; }

.detailtermscondition {
  padding-left: 30px;
  margin-bottom: 62px;
  margin-top: 70px; }
  .detailtermscondition .product-detail-subtitle {
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif;
    font-size: 26px;
    line-height: 20px;
    letter-spacing: -0.610435px; }
  .detailtermscondition p {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.226667px;
    margin: 0 0 20px;
    color: #828282;
    white-space: pre-line;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }

.product-quickview .detailtermscondition {
  padding-left: 0;
  margin-top: 30px;
  margin-bottom: 40px; }

.newWrappper-campaign-Box {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 40px;
  padding-bottom: 46px;
  padding-top: 10px;
  margin-top: 46px;
  z-index: 2;
  position: relative; }
  .newWrappper-campaign-Box .upper-new-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-right: 15px;
    padding-left: 3px;
    margin-bottom: 4px; }
    .newWrappper-campaign-Box .upper-new-area .campaign-icons {
      position: static; }
  .newWrappper-campaign-Box .campaign-image-block {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    overflow: visible;
    position: relative; }
    .newWrappper-campaign-Box .campaign-image-block .miniSlider {
      border-radius: 36px;
      overflow: hidden;
      height: 291px; }
    .newWrappper-campaign-Box .campaign-image-block img {
      width: 100%;
      height: auto; }
    .newWrappper-campaign-Box .campaign-image-block .swiper-pagination {
      -webkit-transition: inherit;
      transition: inherit;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 18px;
      width: auto;
      padding: 0 3px;
      right: 22px;
      left: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 25px;
      bottom: 22px; }
      .newWrappper-campaign-Box .campaign-image-block .swiper-pagination .swiper-pagination-bullet {
        background: rgba(255, 255, 255, 0.2);
        width: 12px;
        height: 12px;
        margin: 0 2px; }
        .newWrappper-campaign-Box .campaign-image-block .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background: white; }
    .newWrappper-campaign-Box .campaign-image-block .swiper-slide {
      position: relative; }
      .newWrappper-campaign-Box .campaign-image-block .swiper-slide:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(10.5deg, black 5%, rgba(0, 0, 0, 0) 83.7%); }
    .newWrappper-campaign-Box .campaign-image-block h3 {
      position: absolute;
      left: 24px;
      bottom: 43px;
      right: 17px;
      font-size: 28px;
      line-height: 33px;
      color: #fff;
      font-family: "ABC Ginto Nord Bold";
      z-index: 1; }
      .newWrappper-campaign-Box .campaign-image-block h3 b {
        font-family: "ABC Ginto Nord Bold Italic";
        background: linear-gradient(208deg, #C90016 4.44%, #FF001C 90.53%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        line-height: 95px;
        font-size: 90px;
        margin-bottom: -15px;
        display: inline-block;
        padding-right: 10px; }
  .newWrappper-campaign-Box .dssNewBadge {
    position: absolute;
    bottom: -25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 7px;
    height: 32px;
    padding: 0 17px;
    font-size: 17px;
    line-height: 19px;
    letter-spacing: -0.63px;
    margin-left: 0;
    margin-right: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #fff500;
    border-radius: 25px;
    white-space: nowrap;
    z-index: 1; }
    .newWrappper-campaign-Box .dssNewBadge .closingIn {
      line-height: 19px;
      letter-spacing: -0.63px;
      color: #3c3c3c;
      font-weight: 400;
      font-family: 'Galano Grotesque Black', sans-serif;
      font-size: 20px; }
      .newWrappper-campaign-Box .dssNewBadge .closingIn span {
        font-size: 20px; }
  .newWrappper-campaign-Box .campaign-info-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 14px;
    padding-left: 19px;
    padding-right: 16px; }
    .newWrappper-campaign-Box .campaign-info-area .product-name-draw-date {
      width: 100%;
      margin-right: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .newWrappper-campaign-Box .campaign-info-area .product-name-draw-date h4 {
        font-size: 26px;
        line-height: 26px;
        letter-spacing: -0.681781px;
        max-width: 100%;
        margin: 0 0 4px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        font-weight: 400;
        font-family: 'Galano Grotesque SemiBold', sans-serif; }
        .newWrappper-campaign-Box .campaign-info-area .product-name-draw-date h4 img {
          margin-left: 5px; }
        .newWrappper-campaign-Box .campaign-info-area .product-name-draw-date h4 .campaign-price {
          color: #4200FF; }
      .newWrappper-campaign-Box .campaign-info-area .product-name-draw-date h6 {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: #929292;
        max-width: 166px;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
    .newWrappper-campaign-Box .campaign-info-area .base-product-image {
      position: static; }
  .newWrappper-campaign-Box .quickView-tab-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .newWrappper-campaign-Box .quickView-tab-header .campaign-tabs {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: stretch;
          -ms-flex-pack: stretch;
              justify-content: stretch;
      list-style: none;
      margin: 0 0 16px;
      padding: 0;
      width: 330px; }
      .newWrappper-campaign-Box .quickView-tab-header .campaign-tabs li {
        width: 50%; }
        .newWrappper-campaign-Box .quickView-tab-header .campaign-tabs li a {
          background: #EBEBEB;
          display: block;
          padding: 11px 0;
          text-align: center;
          font-size: 13px;
          letter-spacing: -0.52px;
          color: #717171;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-weight: 400;
          font-family: 'Galano Grotesque', sans-serif; }
        .newWrappper-campaign-Box .quickView-tab-header .campaign-tabs li.active a {
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif;
          color: #000;
          background: #ffffff; }
        .newWrappper-campaign-Box .quickView-tab-header .campaign-tabs li:first-child a {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
  .newWrappper-campaign-Box .campaign-tabs-content {
    padding: 0 20px; }
    .newWrappper-campaign-Box .campaign-tabs-content .content-campaign {
      display: none; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h3 {
        font-weight: normal;
        letter-spacing: -0.6px;
        line-height: 20px;
        margin-top: 0;
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif;
        font-size: 18px; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h3 b {
          font-weight: normal;
          display: block;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h3.secondary-title {
          color: #000; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h4 {
        font-weight: normal;
        letter-spacing: -0.6px;
        line-height: 20px;
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif;
        font-size: 18px;
        margin: 0 0 5px; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h4 b {
          font-weight: normal;
          display: block;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
          .newWrappper-campaign-Box .campaign-tabs-content .content-campaign h4 b span {
            color: #4200FF; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .two-column {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 20px; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .two-column h3 {
          width: 100%; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .specifcation-module {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 20px; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .price {
        color: #000;
        font-size: 14px;
        letter-spacing: -0.3px;
        width: 260px;
        text-align: right;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .price b {
          color: #4200FF;
          font-weight: normal;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .content-box {
        color: #828282;
        line-height: 20px /*rtl:ignore*/;
        letter-spacing: -0.2px;
        font-size: 13px /*rtl:ignore*/;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-top: 10px;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .content-box .content-divider {
          background: #ebebeb;
          height: 16px;
          margin-top: 29px;
          margin-bottom: 29px;
          margin-left: -36px;
          margin-right: -86px; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign p {
        letter-spacing: -0.3px;
        font-size: 13px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 20px;
        color: #898989; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign:first-child {
        display: block; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners {
        white-space: normal;
        margin: 29px -15px 40px;
        position: relative; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners .swiper-slide {
          height: 240px;
          overflow: hidden;
          position: relative; }
          .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners .swiper-slide img {
            position: absolute;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            top: 50%;
            left: 0;
            right: 0; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners .swiper-pagination {
          bottom: -17px; }
          .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners .swiper-pagination .swiper-pagination-bullet {
            background: #828282;
            -webkit-box-shadow: 0 0 0 1px #828282;
                    box-shadow: 0 0 0 1px #828282;
            width: 4px;
            height: 4px;
            margin-right: 5px; }
            .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .main-site-banners .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
              background: #4a4a4a;
              -webkit-box-shadow: 0 0 0 1px #4a4a4a;
                      box-shadow: 0 0 0 1px #4a4a4a; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker .swiper-slide .inner-slide {
        padding-left: 9%; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker h3 {
        font-size: 10px;
        line-height: 17px;
        height: 16px;
        letter-spacing: -0.151111px;
        font-weight: normal;
        margin-bottom: 0;
        text-align: left;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker h4 {
        font-size: 10px;
        line-height: 11px;
        letter-spacing: -0.151111px;
        font-weight: normal;
        text-align: left;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker h4 b {
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
      .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker .swiper-pagination {
        width: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker .swiper-pagination .swiper-pagination-bullet {
          background: #e3e3e3;
          width: 5px;
          height: 5px;
          opacity: 1;
          margin: 2px 0; }
          .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background: #a2a2a2; }
        .newWrappper-campaign-Box .campaign-tabs-content .content-campaign .bottom-ticker .swiper-pagination.swiper-pagination-lock {
          display: none; }
    .newWrappper-campaign-Box .campaign-tabs-content .detailtermscondition h3 {
      margin-bottom: 5px; }
    .newWrappper-campaign-Box .campaign-tabs-content .detailtermscondition p {
      white-space: pre-line;
      margin-top: 0; }
  .newWrappper-campaign-Box .specifcation-module ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .newWrappper-campaign-Box .specifcation-module li {
    border-bottom: 1px solid #e6e6e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 8px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 8px; }
    .newWrappper-campaign-Box .specifcation-module li:last-child {
      padding-bottom: 0;
      border-bottom: 0; }
    .newWrappper-campaign-Box .specifcation-module li .key {
      width: 40%;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000; }
    .newWrappper-campaign-Box .specifcation-module li .value {
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000; }

.user-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative; }
  .user-action .wishlist-icon,
  .user-action .share-trigger {
    margin-bottom: 10px;
    background: #f6f6f6;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    background: #FFFFFF;
    -webkit-box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.05);
            box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.05);
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    letter-spacing: -0.234px;
    font-size: 10px;
    line-height: 12px;
    color: #000; }
    .user-action .wishlist-icon img,
    .user-action .share-trigger img {
      width: 24px; }
  .user-action img {
    max-width: 24px;
    width: 100%; }
  .user-action .add-to-wishlist-product a {
    margin-right: 27px; }
  .user-action .wishlist-icon {
    cursor: pointer;
    color: #000; }
    .user-action .wishlist-icon .wishIcon {
      font-size: 20px;
      margin-top: 0;
      background: url(../images/m/icons/wishlist-new.png) no-repeat center top;
      background-size: 100%;
      width: 28px;
      height: 25px;
      color: #000; }
    .user-action .wishlist-icon.added .wishIcon {
      background: url(../images/m/icons/wishlist-new-active.png) no-repeat center top;
      background-size: 100%; }
  .user-action .toolTip {
    position: absolute;
    right: 110%;
    top: 12px;
    height: 34px;
    padding: 0 15px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: none;
    white-space: pre;
    border-radius: 25px;
    -webkit-box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
            box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.226667px;
    color: #000;
    background: #fff;
    opacity: 0;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }

.recommended-timer {
  width: 147px;
  height: 66px;
  background: #F6F6F6;
  border-radius: 50px;
  padding: 13px 20px 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  letter-spacing: -0.3px;
  font-size: 11px;
  line-height: 21px;
  color: #000;
  font-weight: 400;
  font-family: 'Galano Grotesque Medium', sans-serif; }
  .recommended-timer b {
    font-weight: normal;
    letter-spacing: -0.5px;
    font-size: 18px;
    line-height: 18px;
    display: block;
    color: #000;
    white-space: nowrap;
    margin-top: -3px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .recommended-timer.red {
    background: #FF404B;
    color: #fff; }
    .recommended-timer.red b {
      color: #fff; }

.homeLogin {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 11114;
  background: rgba(0, 0, 0, 0.7); }
  .homeLogin.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .homeLogin .new-claim-box {
    border-radius: 55px;
    background: -webkit-gradient(linear, left top, left bottom, from(#F00), color-stop(70.32%, #FF00DF));
    background: linear-gradient(180deg, #F00 0%, #FF00DF 70.32%);
    padding: 40px 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 450px; }
  .homeLogin .win {
    width: 90%;
    margin-bottom: 20px; }
  .homeLogin .claim-btn {
    width: 90%; }
    .homeLogin .claim-btn img {
      width: 100%; }
    .homeLogin .claim-btn.actualBtn {
      color: #fff;
      -webkit-box-shadow: 0px 8.818px 17.636px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 8.818px 17.636px rgba(0, 0, 0, 0.25);
      height: 67px;
      border-radius: 67px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      letter-spacing: -0.501px;
      font-size: 17px;
      font-weight: 400;
      font-family: 'Galano Grotesque SemiBold', sans-serif;
      background: #000;
      margin: 0 0 27px; }
      .homeLogin .claim-btn.actualBtn img {
        margin-right: 13px;
        width: 35px; }
  .homeLogin .existing-user {
    color: #fff;
    letter-spacing: -0.501px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Galano Grotesque SemiBold', sans-serif;
    text-align: center; }
  .homeLogin .homeLoginH2 {
    font-size: 54px;
    letter-spacing: 0.547px;
    color: #fff;
    text-align: left;
    font-family: "ABC Ginto Nord Bold";
    line-height: 50px;
    width: 90%; }
    .homeLogin .homeLoginH2 span {
      font-size: 61px;
      display: block; }
      .homeLogin .homeLoginH2 span:lang(es) {
        font-size: 44px; }
    .homeLogin .homeLoginH2:lang(es) {
      font-size: 36px;
      line-height: 40px; }
  .homeLogin .homeLoginH5 {
    color: #fff;
    line-height: 17px;
    font-size: 17px;
    width: 90%;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
  .homeLogin .homeLoginH3 {
    font-size: 44px;
    letter-spacing: 0.444px;
    color: #000;
    width: 90%;
    margin: 0 0 18px;
    line-height: 44px;
    text-align: center;
    font-family: "ABC Ginto Nord Bold"; }
    .homeLogin .homeLoginH3:lang(es) {
      font-size: 26px; }

.verify-box {
  margin-top: 107px; }
  .verify-box h5 {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif;
    line-height: 21.379px;
    /* 125.76% */
    letter-spacing: -0.5px;
    text-align: center;
    margin: 0; }
    .verify-box h5.red {
      color: #FF0004;
      margin-bottom: 12px; }
  .verify-box form {
    margin: 0 15px;
    width: 50%;
    margin: 0 auto; }
    .verify-box form .mobile-box-new {
      border: 1px solid #DADADA;
      font-size: 16px;
      margin: 0 0 14px;
      padding: 25px 16px 15px;
      border-radius: 12px;
      background: #fff;
      margin-top: 16px; }
    .verify-box form .registered-number {
      margin-bottom: 20px; }
      .verify-box form .registered-number .register-label {
        font-size: 12px;
        letter-spacing: -0.357px;
        line-height: 12px; }
      .verify-box form .registered-number .register-number {
        font-size: 15px;
        letter-spacing: -0.357px;
        font-weight: 400;
        font-family: 'Galano Grotesque Bold', sans-serif; }
    .verify-box form .notfication-wrapper-single {
      font-size: 12px;
      letter-spacing: -0.357px;
      line-height: 20px; }
      .verify-box form .notfication-wrapper-single .newSwitch {
        width: 42px;
        margin-right: 7px; }
        .verify-box form .notfication-wrapper-single .newSwitch input:checked + .slider:before {
          -webkit-transform: translateX(19px);
                  transform: translateX(19px); }
    .verify-box form .required-items {
      font-size: 12px;
      letter-spacing: -0.357px;
      margin: 11px 0 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .verify-box form .required-items span {
        margin-right: 5px;
        height: 12px; }
    .verify-box form .new-btn {
      font-size: 22px;
      letter-spacing: -0.176px;
      text-transform: uppercase;
      font-family: "ABC Ginto Nord Bold";
      text-align: center;
      background: -webkit-gradient(linear, left top, left bottom, from(#7367FF), to(#4200FF));
      background: linear-gradient(180deg, #7367FF 0%, #4200FF 100%);
      -webkit-box-shadow: 0px 7.992px 15.985px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 7.992px 15.985px rgba(0, 0, 0, 0.25);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 70px;
      border-radius: 50px;
      width: 100%;
      color: #fff;
      border: 0; }
      .verify-box form .new-btn.is-disabled {
        background: #cccccc;
        pointer-events: none;
        -webkit-box-shadow: none;
                box-shadow: none; }
      .verify-box form .new-btn.smaller {
        font-size: 18px; }
    .verify-box form .registeration_agreement .checkbox-styling .checkmark {
      background-color: #fff; }
  .verify-box .change-number {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -8px;
    margin-top: 16px;
    display: none; }
    .verify-box .change-number .input-field {
      border: 0;
      border-top: 1px solid rgba(218, 218, 218, 0.5);
      border-radius: 0;
      margin-bottom: 0; }
